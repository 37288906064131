import React from "react";

function Recycling({color, width, height}) {
    return (
        <svg
            ariaHidden="true"
            ariaLabel="fontSize medium"
            style={{fill: color, width: 25}}
            data-testid="RecyclingIcon"
            viewBox="0 0 24 24"
        >
            <path d="M5.77 7.15L7.2 4.78l1.03-1.71c.39-.65 1.33-.65 1.72 0l1.48 2.46-1.23 2.06-1 1.62zm15.95 5.82l-1.6-2.66-3.46 2L18.87 16H20a2 2 0 002-2c0-.36-.1-.71-.28-1.03M16 21h1.5a2 2 0 001.79-1.11L20.74 17H16v-2l-4 4 4 4zm-6-4H5.7l-.84 1.41c-.3.5-.32 1.12-.06 1.65.28.57.87.94 1.52.94H10zm-3.88-2.65l1.73 1.04L6.48 9.9 1 11.27l1.7 1.02-.41.69c-.35.59-.38 1.31-.07 1.92l1.63 3.26zm10.9-9.21l-1.3-2.17C15.35 2.37 14.7 2 14 2h-3.53l3.12 5.2-1.72 1.03 5.49 1.37 1.37-5.49z"></path>
        </svg>
    );
}

export default Recycling;
