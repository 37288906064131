import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Logo from '../logo.svg';
import { useForm } from 'react-hook-form';

async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/login?relocationNumber=${credentials.id}&customerEmail=${credentials.email}`, {
        method: 'GET',
        headers: {
            'x-api-key':process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json'
        }
    })
        .then(data => {
            return data.status === 403 ? 0 : data.json()
        })
}

export default function Login({setToken}) {
    const queryParameters = new URLSearchParams(window.location.search)
    const id_url = queryParameters.get("id")
    const email_url = queryParameters.get("email")

    useEffect( () => {
        async function fetchData() {
            const response = await loginUser({
                "id":id_url,
                "email":email_url
            });
            setToken(response)
        }

       if (!id_url && email_url) {
            let url = window.location.search
            url = url.replace("?=","?id=")
            window.location.href = url
        }

        if (id_url && email_url) {
            fetchData();
        }
    },[]);

    const { register, handleSubmit, formState: { errors },setError, clearErrors } = useForm();
    const onSubmit = async data => {
        const {Id, Email} = data
        const token = await loginUser({
            "id":Id,
            "email":Email
        });
        token === 0 && setError('wrong', { type: 'custom', message: 'custom message' });

        setToken(token);
    };

    return(<>
            <div className="flex min-h-screen flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img className="mb-12" alt="Logo UPV" width="250px" src={Logo}/>
                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Mein <span className="text-[#ffcc00]">Umzug</span>
                            </h2>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div className="mt-12">
                                {errors.wrong && (
                                    <p className="text-red-400 text-xs">Bitte überprüfen Sie Ihre Login Daten.</p>
                                )}
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Anfragenummer
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text" {...register("Id", {required: true, onChange: (e) => clearErrors()})}
                                        placeholder="UM-12345 oder AN-12345"
                                        className="px-3 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus-visible:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-3 sm:text-sm sm:leading-6"
                                    />
                                    {errors.Id && (
                                        <p className="text-red-400 text-xs">Bitte geben Sie Ihre persönliche Anfragenummer ein </p>
                                    )}
                                </div>
                            </div>
                            <div className="mt-8">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        placeholder="example@mail.de"
                                        {...register("Email", {onChange: (e) => clearErrors(), required: true, pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i})}
                                        className="px-3 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus-visible:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-3 sm:text-sm sm:leading-6"
                                    />
                                    {errors.Email && (
                                        <p className="text-red-400 text-xs">Bitte geben Sie ihre persönliche Mail Adresse ein</p>
                                    )}
                                </div>
                            </div>
                            <div className="my-12">
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-accent-3 hover:bg-accent-1 hover:text-white px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-accent-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-1"
                                >
                                    Einloggen
                                </button>
                            </div>
                        </form>
                        <div className="flex justify-center">
                            <a rel="noreferrer" href="https://umzugspreisvergleich.de/impressum" target="_blank" className="text-gray-400">Impressum</a>
                            <a rel="noreferrer" href="https://umzugspreisvergleich.de/datenschutz" target="_blank" className="ml-2 text-gray-400">Datenschutzerklärung</a>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="/img/bg.jpg"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}