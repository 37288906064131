import {Disclosure} from "@headlessui/react";
import {FormattedMessage} from "react-intl";
import {sumValues} from "./Common";
import {MinusIcon, PlusIcon} from "@heroicons/react/20/solid";
import calculateVolume from "../Common/CalculateVolume";
import Toggle from "../Common/Toggle";

export default function List({counter, open, setValue, setShowNotification, setSendButton, listType, dataInfo, register, watch, setDataInfo}) {


    const handleNumberInput = (e) =>{
        if(e.target.value < 0 || e.target.value == -0){
            e.target.value = 0
        }else if(e.target.value >= 0 && !e.target.value){
            e.target.value = 0
        }else if(e.target.value > 0){
            e.target.value = e.target.value.replace(/^0+/, "")
        }
    }
    
    return listType.map((x)=>(
                <Disclosure defaultOpen={open} as="div" key={x.key} className=" px-4 py-7 relative">
                    {({ open }) => (
                        <>
                            <h3 className="z-[1] -mx-4 -my-5 flow-root sticky top-0 lg:top-[196px] px-4 shadow-md rounded-lg  bg-white">
                                <Disclosure.Button className="text-left flex w-full items-center justify-between py-6 text-gray-400 hover:text-gray-500">
                                    {open ? (
                                        <span className="font-medium text-accent-1 flex items-center">
                                            <FormattedMessage id={x.key} />
                                            {x.key !== "setup" && (
                                                <span className="text-center bg-accent-1 ml-3 rounded-md text-white min-w-[30px]  px-1.5 py-1 block">
                                                    {counter?counter[x.key]:dataInfo&&sumValues(dataInfo[x.key])}
                                                </span>
                                            )}
                                        </span>
                                    ) : (
                                        <span className="font-medium text-gray-900 flex items-center">
                                            <FormattedMessage id={x.key}/>
                                                {x.key !== "setup" && (
                                                    <span className="text-center bg-accent-1 ml-3 rounded-md text-white min-w-[30px]  px-1.5 py-1 block">
                                                        {counter?counter[x.key]:dataInfo&&sumValues(dataInfo[x.key])}
                                                    </span>
                                                )}
                                        </span>
                                    )}

                                    <span className="ml-6 flex items-center">
                                        {open ? (
                                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                        ) : (
                                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                        )}
                                    </span>
                                </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                                <div className="">

                                    {x.possessions.map((option, optionIdx) => (
                                        <div key={option.label}>
                                            {option.type === "text" &&
                                                <div  className="col-span-full py-6">
                                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                                        <FormattedMessage id={option.label} />
                                                    </label>
                                                    <div className="mt-2">
                                                        {dataInfo && (
                                                            <textarea
                                                                className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-1 sm:text-sm sm:leading-6 focus-visible:outline-accent-1"
                                                                rows={3}
                                                                maxLength={255}
                                                                {...register(option.label, { 
                                                                    onChange:(x)=>{
                                                                        setShowNotification(false)
                                                                        setSendButton(true)
                                                                    },
                                                                    value: ((dataInfo?.[x.key].find(obj => option.label in obj) || {})[option.label]) ? ((dataInfo?.[x.key].find(obj => option.label in obj) || {})[option.label]) : ""
                                                                })}
                                                            />
                                                        )}
                                                    </div>
                                                    <p className="mt-3 text-sm leading-6 text-gray-600">Weitere Informationen über <FormattedMessage id={option.label} />.</p>
                                                </div> }
                                            {option.type === "number" &&
                                                <div  className="border-b-2 border-gray-300 grid grid-cols-4 items-center justify-end gap-4 py-6 ">
                                                    <label htmlFor="username" className="col-span-3 font-medium leading-6 text-gray-900">
                                                        <FormattedMessage id={option.label} />
                                                    </label>
                                                    {dataInfo?.possessionsListEditingActivated === 1 ? (
                                                        <div className="col-span-1 mt-0 flex items-center justify-center gap-2">
                                                            <button type="button" disabled={!Number(watch(option.label))>0} onClick={(event)=>{
                                                                setShowNotification(false)
                                                                setSendButton(true)
                                                                calculateVolume(dataInfo, setDataInfo, x.key, option.label, watch(option.label)-1)
                                                                return setValue(option.label, (watch(option.label)-1))
                                                            }} className="text-white bg-accent-1 rounded-lg hover:bg-accent-3"><MinusIcon className="h-6 w-6"/></button>
                                                            <div className="w-10 h-10 flex items-center font-semibold justify-center text-black">

                                                                {dataInfo && (
                                                                    <input
                                                                        className="block w-10 text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-1 sm:text-sm sm:leading-6 focus-visible:outline-accent-1"
                                                                        type="number"
                                                                        min="0"
                                                                        onInput={(e)=>{handleNumberInput(e)}}
                                                                        {...register(option.label, {
                                                                            onChange:(event)=>{
                                                                                setShowNotification(false)
                                                                                setSendButton(true)
                                                                                calculateVolume(dataInfo, setDataInfo, x.key, option.label, watch(option.label))
                                                                            },
                                                                            valueAsNumber:true,
                                                                            value: ((dataInfo?.[x.key].find(obj => option.label in obj) || {})[option.label]) ? ((dataInfo?.[x.key].find(obj => option.label in obj) || {})[option.label]) : 0
                                                                        })}
                                                                    />
                                                                )}
                                                            </div>
                                                            <button type="button" onClick={(event)=>{
                                                                setShowNotification(false)
                                                                setSendButton(true)
                                                                calculateVolume(dataInfo, setDataInfo, x.key, option.label, watch(option.label)+1)
                                                                return setValue(option.label, (watch(option.label))+1)
                                                            }} className="text-white bg-accent-1 rounded-lg hover:bg-accent-3"><PlusIcon className="h-6 w-6"/></button>
                                                        </div>
                                                    ): (
                                                        <div className="w-10 h-10 justify-self-end flex items-center font-semibold justify-end text-black">

                                                            {dataInfo && (
                                                                <input
                                                                    disabled
                                                                    className="block w-10 text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-1 sm:text-sm sm:leading-6 focus-visible:outline-accent-1"
                                                                    type="number"
                                                                    {...register(option.label, {
                                                                        onChange:(x)=>{
                                                                            setShowNotification(false)
                                                                            setSendButton(true)
                                                                        },
                                                                        valueAsNumber:true,
                                                                        value: ((dataInfo?.[x.key].find(obj => option.label in obj) || {})[option.label]) ? ((dataInfo?.[x.key].find(obj => option.label in obj) || {})[option.label]) : 0
                                                                    })}
                                                                />
                                                            )}
                                                        </div>

                                                    )}

                                                </div>
                                            }
                                            {option.type === "boolean" && (
                                                dataInfo?.possessionsListEditingActivated === 1 ? <div  className="border-b-2 border-gray-300 grid grid-cols-4 items-center justify-end gap-4 py-6 ">
                                                    <label htmlFor="username" className="col-span-3 font-medium leading-6 text-gray-900">
                                                        <FormattedMessage id={option.label} />
                                                    </label>

                                                    <div className="justify-end flex">
                                                        <div className="flex gap-3">Nein <Toggle setShowNotification={setShowNotification} setSendButton={setSendButton} fieldName={option.label} setValue={setValue} isEnabled={((dataInfo?.[x.key].find(obj => option.label in obj) || {})[option.label])}/> Ja</div>
                                                    </div>
                                                </div>
                                                : 
                                                <div  className="border-b-2 border-gray-300 grid grid-cols-4 items-center justify-end gap-4 py-6 ">
                                                    <label htmlFor="username" className="col-span-3 font-medium leading-6 text-gray-900">
                                                        <FormattedMessage id={option.label} />
                                                    </label>

                                                    <div className="justify-end flex">
                                                        {((dataInfo?.[x.key].find(obj => option.label in obj) || {})[option.label]) ? "Ja": "Nein"}
                                                    </div>
                                                </div>)
                                            }

                                        </div>
                                    ))}
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            ))
}