export const mainPossessions = [
    {
        key: 'livingRoom',
        possessions: [
            { label: 'livingRoom_wallUnit', type: 'number' },
            { label: 'livingRoom_bookShelf', type: 'number' },
            { label: 'livingRoom_buffetWithTop', type: 'number' },
            { label: 'livingRoom_buffetWithoutTop', type: 'number' },
            { label: 'livingRoom_tv', type: 'number' },
            { label: 'livingRoom_tvTable', type: 'number' },
            { label: 'livingRoom_grandPiano', type: 'number' },
            { label: 'livingRoom_piano', type: 'number' },
            { label: 'livingRoom_massiveDesk', type: 'number' },
            { label: 'livingRoom_liteDesk', type: 'number' },
            { label: 'livingRoom_sideboard', type: 'number' },
            { label: 'livingRoom_armchair', type: 'number' },
            { label: 'livingRoom_couch', type: 'number' },
            { label: 'livingRoom_sewingMachine', type: 'number' },
            { label: 'livingRoom_chair', type: 'number' },
            { label: 'livingRoom_table', type: 'number' },
            { label: 'livingRoom_mediaPlayer', type: 'number' },
            { label: 'livingRoom_closet', type: 'number' },
            { label: 'livingRoom_electricPiano', type: 'number' },
            { label: 'livingRoom_comments', type: 'text' },
        ]
    },
    {
        key: 'study',
        possessions: [
            { label: 'study_filingCabinet', type: 'number' },
            { label: 'study_bookShelf', type: 'number' },
            { label: 'study_itDevice', type: 'number' },
            { label: 'study_desk', type: 'number' },
            { label: 'study_chair', type: 'number' },
            { label: 'study_armchair', type: 'number' },
            { label: 'study_couch', type: 'number' },
            { label: 'study_table', type: 'number' },
            { label: 'study_copier', type: 'number' },
            { label: 'study_sideboard', type: 'number' },
            { label: 'study_cornerDesk', type: 'number' },
            { label: 'study_comments', type: 'text' },
        ]
    },
    {
        key: 'bedroom',
        possessions: [
            { label: 'bedroom_doubleBed', type: 'number' },
            { label: 'bedroom_slidingDoorCloset', type: 'number' },
            { label: 'bedroom_singleBed', type: 'number' },
            { label: 'bedroom_tv', type: 'number' },
            { label: 'bedroom_tvTable', type: 'number' },
            { label: 'bedroom_dresser', type: 'number' },
            { label: 'bedroom_bedsideTable', type: 'number' },
            { label: 'bedroom_compactCloset', type: 'number' },
            { label: 'bedroom_dismantableCloset', type: 'number' },
            { label: 'bedroom_chair', type: 'number' },
            { label: 'bedroom_comments', type: 'text' },
        ]
    },
    {
        key: 'nursery',
        possessions: [
            { label: 'nursery_wallUnit', type: 'number' },
            { label: 'nursery_bookShelf', type: 'number' },
            { label: 'nursery_singleBed', type: 'number' },
            { label: 'nursery_bedding', type: 'number' },
            { label: 'nursery_loftBed', type: 'number' },
            { label: 'nursery_childBed', type: 'number' },
            { label: 'nursery_dresser', type: 'number' },
            { label: 'nursery_playPen', type: 'number' },
            { label: 'nursery_bedsideTable', type: 'number' },
            { label: 'nursery_compactCloset', type: 'number' },
            { label: 'nursery_dismantableCloset', type: 'number' },
            { label: 'nursery_chair', type: 'number' },
            { label: 'nursery_desk', type: 'number' },
            { label: 'nursery_couch', type: 'number' },
            { label: 'nursery_toyBox', type: 'number' },
            { label: 'nursery_table', type: 'number' },
            { label: 'nursery_comments', type: 'text' },
        ]
    },
    {
        key: 'kitchen',
        possessions: [
            { label: 'kitchen_counterTop', type: 'number' },
            { label: 'kitchen_broomCabinet', type: 'number' },
            { label: 'kitchen_buffetWithTop', type: 'number' },
            { label: 'kitchen_buffetWithoutTop', type: 'number' },
            { label: 'kitchen_kitchenBench', type: 'number' },
            { label: 'kitchen_stove', type: 'number' },
            { label: 'kitchen_smallFridge', type: 'number' },
            { label: 'kitchen_largeFridge', type: 'number' },
            { label: 'kitchen_cabinet', type: 'number' },
            { label: 'kitchen_microwave', type: 'number' },
            { label: 'kitchen_chair', type: 'number' },
            { label: 'kitchen_table', type: 'number' },
            { label: 'kitchen_dishWasher', type: 'number' },
            { label: 'kitchen_freezer', type: 'number' },
            { label: 'kitchen_liquorCabinet', type: 'number' },
            { label: 'kitchen_sideboard', type: 'number' },
            { label: 'kitchen_comments', type: 'text' },
        ]
    },
    {
        key: 'bathroom',
        possessions: [
            { label: 'bathroom_cupboard', type: 'number' },
            { label: 'bathroom_linenCloset', type: 'number' },
            { label: 'bathroom_chair', type: 'number' },
            { label: 'bathroom_closet', type: 'number' },
            { label: 'bathroom_washer', type: 'number' },
            { label: 'bathroom_vacuumCleaner', type: 'number' },
            { label: 'bathroom_ironingBoard', type: 'number' },
            { label: 'bathroom_wardrobe', type: 'number' },
            { label: 'bathroom_dresser', type: 'number' },
            { label: 'bathroom_shoeCabinet', type: 'number' },
            { label: 'bathroom_comments', type: 'text' },
        ]
    },

    {
        key: 'basement',
        possessions: [
            { label: 'basement_stroller', type: 'number' },
            { label: 'basement_suitcase', type: 'number' },
            { label: 'basement_shelf', type: 'number' },
            { label: 'basement_sled', type: 'number' },
            { label: 'basement_ski', type: 'number' },
            { label: 'basement_workBench', type: 'number' },
            { label: 'basement_toolCabinet', type: 'number' },
            { label: 'basement_toolBox', type: 'number' },
            { label: 'basement_comments', type: 'text' },
        ]
    },
    {
        key: 'garage',
        possessions: [
            { label: 'garage_tire', type: 'number' },
            { label: 'garage_planter', type: 'number' },
            { label: 'garage_triCycle', type: 'number' },
            { label: 'garage_bike', type: 'number' },
            { label: 'garage_foldingTable', type: 'number' },
            { label: 'garage_ladder', type: 'number' },
            { label: 'garage_garbageCan', type: 'number' },
            { label: 'garage_mower', type: 'number' },
            { label: 'garage_shelf', type: 'number' },
            { label: 'garage_wheelBarrow', type: 'number' },
            { label: 'garage_pingPongTable', type: 'number' },
            { label: 'garage_comments', type: 'text' },
        ]
    }
]

export const secondaryPossessions = [

    {
        key: 'misc',
        possessions: [
            { label: 'misc_ceilingLamp', type: 'number' },
            { label: 'misc_floorLamp', type: 'number' },
            { label: 'misc_pictures', type: 'number' },
            { label: 'misc_carpet', type: 'number' },
            { label: 'misc_plantSmall', type: 'number' },
            { label: 'misc_plantLarge', type: 'number' },
            { label: 'misc_mirror', type: 'number' },
            { label: 'misc_fitnessEquipment', type: 'number' },
            { label: 'misc_comments', type: 'text' }
        ]
    }

]

export const sidebarPossessions = [

    {
        key: 'boxes',
        possessions: [
            { label: 'boxes_orderedBookBox', type: 'number' },
            { label: 'boxes_orderedClothesBox', type: 'number' },
            { label: 'boxes_orderedDishBox', type: 'number' },
            { label: 'boxes_orderedStandardBox', type: 'number' },
            { label: 'boxes_presentBox', type: 'number' },
        ]
    },
    {
        key: 'setup',
        possessions: [
            { label: 'setup_furnitureDisassemble', type: 'boolean' },
            { label: 'setup_kitchenDisassemble', type: 'boolean' },
            { label: 'setup_boxesPack', type: 'boolean' },
            { label: 'setup_furnitureAssemble', type: 'boolean' },
            { label: 'setup_kitchenAssemble', type: 'boolean' },
            { label: 'setup_boxesUnpack', type: 'boolean' },
            { label: 'setup_comments', type: 'text' }
        ]
    }
]

