import React from "react";

export default function PreloaderTable() {
    return (
        <table className="animate-pulse min-w-full ">
            <thead>
            <tr className="">
                <th
                    scope="col"
                    className="h-20 z-10 hidden px-3 py-3.5 text-center text-sm font-bold text-gray-900 lg:table-cell"
                >
                    Nr.
                </th>

                <th scope="col" className="h-20 hidden lg:table-cell z-10 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-3">
                    <span className="hidden lg:block">Datum</span>
                </th>

                <th scope="col" className="h-20 hidden lg:table-cell z-10 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-3">
                    <span className="hidden lg:block"></span>
                </th>

                <th scope="col" className="h-20 hidden lg:table-cell z-10 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-3">
                    <span className="hidden lg:block"></span>
                </th>

                <th
                    scope="col"
                    className="h-20 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                    Termin
                </th>

                <th
                    scope="col"
                    className="h-20 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                </th>

                <th
                    scope="col"
                    className="h-20 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                </th>
                <th
                    scope="col"
                    className="h-20 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                </th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>

            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>

            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>

            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>

            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>

            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>

            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>

            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>

            <tr className="text-slate-500 cursor-pointer hover:bg-slate-50">
                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

                <td className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>
                <td>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </td>

            </tr>
            </tbody>
        </table>
    )
}