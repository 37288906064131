import {
    ClockIcon
} from "@heroicons/react/20/solid";
import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function BodyO({data, openDetail}) {
    return (
        <tbody className="divide-y divide-gray-200 bg-white">
        {data?.deliveries.length>0 ? data?.deliveries.map((bid) => (
            <tr className={classNames(bid.recomendation && 'bg-slate-100 text-slate-500', 'text-slate-500 cursor-pointer hover:bg-slate-50')} key={bid.deliveryNumber}>
                <td onClick={()=>openDetail(bid.deliveryId)} className={classNames(bid.highlightDelivery === 1 && 'border-[3px] border-r-0 border-accent-3 rounded-l-lg relative', 'hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold')}>
                {bid.highlightDelivery === 1 && <span className="absolute -top-4 inline-flex items-center rounded-full bg-accent-3 px-3 py-1 text-xs font-medium text-black">
                    Highlight
                </span>}
                    {bid.deliveryNumber}
                </td>
                <td onClick={()=>openDetail(bid.deliveryId)}  className={classNames(bid.highlightDelivery === 1 && 'border-[3px] border-r-0 lg:border-l-0 border-accent-3', 'hidden lg:table-cell py-4 pl-4 pr-3 text-sm text-center font-medium lg:w-auto lg:max-w-none lg:pl-3')}>
                    <span className="hidden lg:block">{new Date(bid.deliveryDistributionDate).toLocaleDateString("de-DE", {day:"2-digit", month:"2-digit", year: "numeric"})}</span>
                </td>
                <td  className={classNames(bid.highlightDelivery === 1 && 'border-[3px] border-accent-3 rounded-lg', 'flex lg:hidden py-4 pl-4 pr-3 text-sm font-medium')}>
                    <dl className="font-normal lg:hidden w-[40%]">
                        <dt className="lg:hidden mt-6 font-semibold">Nr.</dt>
                        <dd className="mt-1 truncate text-gray-500">{bid.deliveryNumber}</dd>
                        <dt className="lg:hidden mt-6 font-semibold">Datum</dt>
                        <dd className="mt-1 truncate text-gray-500">{new Date(bid.deliveryDistributionDate).toLocaleDateString("de-DE",{day:"2-digit", month:"2-digit", year: "numeric"})}</dd>
                        <dt className="lg:hidden mt-6 font-semibold">Termin</dt>
                        <dd className="mt-1 truncate text-gray-500">{bid.deliveryStatus}</dd>
                        <dd onClick={()=>openDetail(bid.deliveryId)} className='lg:hidden mt-5 text-sm'>
                            <div className="flex mx-auto">
                                    { 
                                        <button onClick={()=>openDetail(bid.deliveryId)} className="bg-accent-3 py-2 px-10 text-gray-800 rounded font-semibold hover:bg-[#FFE066]">Details</button>
                                    }
                            </div>
                        </dd>
                    </dl>
                    <dl className="w-[60%]">
                        <div className="flex flex-col place-content-around">
                            <dl className="font-normal lg:hidden">
                                <dt className="lg:hidden mt-6 font-semibold">Name Spedition </dt>
                                <dd className="mt-1 text-gray-500 lg:hidden">
                                    <div className="flex items-center">
                                        {bid.accountName}
                                    </div>
                                </dd>

                                <dt className="lg:hidden mt-6 font-semibold">Mail Spedition</dt>
                                <dd className="mt-1 text-gray-500 lg:hidden">
                                    <a href={`mailto:${bid.accountEmail}`} className="cursor-pointer flex items-center">
                                        {bid.accountEmail}
                                    </a>
                                </dd>

                                <dt className="lg:hidden mt-6 font-semibold">Telefon Spedition</dt>
                                <dd className="mt-1  text-gray-500 lg:hidden">
                                    <a href={`tel:${bid.accountPhoneNumber}`} className="cursor-pointer text-accent-1 hover:text-accent-3 flex items-center">
                                        {bid.accountPhoneNumber}
                                    </a>

                                </dd>

                                <dd className="mt-6 text-gray-500 lg:hidden">
                                    <div className="flex justify-start mx-auto">
                                        {
                                        bid.highlightDelivery === 1 &&  <div className="bg-accent-3 py-1 px-3 text-gray-800 rounded-3xl font-semibold">Highlight</div>
                                        }
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </dl>
                </td>
                <td onClick={()=>openDetail(bid.deliveryId)} className={classNames(bid.highlightDelivery === 1 && 'border-[3px] lg:border-r-0 border-l-0 border-accent-3', 'hidden lg:table-cell w-[65%] py-4 pl-4 pr-3 text-sm font-medium lg:w-auto lg:max-w-none lg:pl-3')}>
                    <span className="hidden lg:block"> {bid.accountName}</span>
                </td>
                <td onClick={()=>openDetail(bid.deliveryId)} className={classNames(bid.highlightDelivery === 1 && 'border-[3px] border-r-0 border-l-0 border-accent-3', 'hidden lg:table-cell px-3 py-4 text-sm')}>
                    <div className="w-max">
                        <a onClick={(e)=>{e.stopPropagation()}} href={`mailto:${bid.accountEmail}`} className="cursor-pointer text-accent-1 hover:text-accent-3 flex items-center">
                            {bid.accountEmail}
                        </a>
                    </div>

                </td>
                <td onClick={()=>openDetail(bid.deliveryId)} className={classNames(bid.highlightDelivery === 1 && 'border-[3px] border-r-0 border-l-0 border-accent-3', 'hidden lg:table-cell px-3 py-4 text-sm')}>
                    <div className="w-max">
                        <a onClick={(e)=>{e.stopPropagation()}} href={`tel:${bid.accountPhoneNumber}`} className="cursor-pointer text-accent-1 hover:text-accent-3 flex items-center">
                            {bid.accountPhoneNumber}
                        </a>
                    </div>

                </td>
                <td onClick={()=>openDetail(bid.deliveryId)} className={classNames(bid.highlightDelivery === 1 && 'border-[3px] border-r-0 border-l-0 border-accent-3', 'hidden lg:table-cell px-3 py-4 text-sm')}>
                    <div className="w-max">
                        <a onClick={(e)=>{e.stopPropagation()}} rel="noreferrer" target="_blank" title={`Google Maps ${bid.invoiceAddress}`} href={`https://maps.google.com/?q=${bid.invoiceAddress}`} className="cursor-pointer text-accent-1 hover:text-accent-3 flex items-center">
                            {bid.invoiceAddress}
                        </a>
                    </div>

                </td>
                <td onClick={()=>openDetail(bid.deliveryId)} className={classNames(bid.highlightDelivery === 1 && 'border-[3px] border-l-0 border-accent-3 rounded-r-lg', 'hidden lg:table-cell px-3 py-4 text-sm')}>
                    <div className="flex justify-center mx-auto">
                            { 
                                <button onClick={()=>openDetail(bid.deliveryId)} className="bg-accent-3 py-2 px-3 text-gray-800 rounded font-semibold hover:bg-[#FFE066]">Details</button>
                            }
                    </div>
                </td>
            </tr>
        )): (
            <tr className="py-24 text-center mx-auto justify-center">
                <td className="text-xl text-gray-400 px-16 lg:px-[300px] mx-auto pt-24 pb-24 flex flex-col justify-center text-center">
                    <ClockIcon className="h-24 w-24 mx-auto mb-4"/>
                    Ihre Anfrage wurde noch nicht an eine Spedition gesendet, bitte schauen Sie später noch einmal vorbei.</td>
            </tr>
        )}
        </tbody>
    )
}