import { StarIcon, TrophyIcon, CheckBadgeIcon} from "@heroicons/react/20/solid";
import { ChatBubbleLeftRightIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import useToken from "../../useToken";
import Notification from "../../Common/Notification";
import {bewertungsText} from "../../Common/Helper";
import Recycling from "../../icons/recycling";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Body({preloader, setPreloader, setCounter, data, openDetail}) {
    const { token } = useToken();
    const [selected,setSelected] = useState();
    const [showNotification, setShowNotification] = useState(false)


    useEffect(() => {
        selected && fetch(`${process.env.REACT_APP_API_BASE_URL}/postSelected?token=${token.token}&bidId=${selected}&selected=true`, {
            method: 'POST',
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            response.status === 200 && setCounter((x)=>x+1)
            return setShowNotification(true)
        }).catch((reason)=>console.log("ERROR: "+reason))
    }, [selected]);





    return <tbody className="divide-y divide-gray-200 bg-white">

        <Notification show={showNotification} setShow={setShowNotification}/>
        {data?.bids.map((bid) => (
            <tr className={classNames(
                bid.recomendation && 'bg-slate-100 cursor-pointer text-slate-500',"flex lg:table-row text-slate-500 cursor-pointer hover:bg-slate-50"
            )} key={bid.bidNumber}>
                <td onClick={()=>openDetail(bid.id)} className="hidden lg:table-cell w-[40px] px-3 py-4 text-sm text-center font-bold">{bid.bidNumber}</td>
                <td onClick={()=>openDetail(bid.id)}  className="hidden lg:table-cell py-4 pl-4 pr-3 text-sm text-center font-medium lg:w-auto lg:max-w-none lg:pl-3">
                    <span>{new Date(bid.createdDate).toLocaleDateString("de-DE", {day:"2-digit", month:"2-digit", year: "numeric"})}</span>
                </td>
                <td className="lg:hidden w-[50%] flex flex-col py-4 pl-4 pr-3 text-sm font-medium justify-between">
                    <dl className="font-normal">
                        <dt className="lg:hidden mt-4 font-semibold">Nr.</dt>
                        <dd className="mt-1 truncate text-gray-500">{bid.bidNumber}</dd>
                        <dt className="lg:hidden mt-4 font-semibold">Datum</dt>
                        <dd className="mt-1 truncate text-gray-500">{new Date(bid.createdDate).toLocaleDateString("de-DE",{day:"2-digit", month:"2-digit", year: "numeric"})}</dd>
                        <dt className="lg:hidden mt-4 font-semibold">Termin</dt>
                        <dd className="mt-1 truncate text-gray-500">{bid.appointementText}</dd>
                        <dt className="lg:hidden mt-4 font-semibold">Bewertung</dt>
                        <dd className="mt-1 text-gray-500 lg:hidden">
                            <div className="flex items-center">
                                {[0, 1, 2, 3, 4].map((rating, index) => (
                                    <div key={rating+index}>
                                        {bewertungsText(bid.ratingAccount, rating)}
                                    </div>
                                ))}
                                <span className="ml-2 font-semibold">{bid.ratingAccount}</span>
                                <span className="font-semibold">{!bid.ratingAccount.toString().split('.')[1] && ".0"}</span>
                            </div>
                        </dd>
                    </dl>
                    <dl className="sm:hidden font-semibold mt-10">
                        <button onClick={()=>openDetail(bid.id)} className="bg-accent-3 py-2 px-10 text-gray-800 rounded font-semibold hover:bg-[#FFE066]">Details</button>     
                    </dl>
                </td>
                <td onClick={()=>openDetail(bid.id)} className="hidden lg:table-cell py-4 pl-4 pr-3 text-sm font-medium lg:w-auto lg:max-w-none lg:pl-3">
                    <span>{bid.appointementText}</span>
                </td>
                <td className="lg:hidden w-[50%] flex flex-col py-4 pl-4 pr-3 text-sm font-medium lg:w-auto lg:max-w-none lg:pl-3">
                    <div className="flex flex-col place-content-around">
                        <dl className="font-normal lg:hidden">
                            <dt className="lg:hidden mt-4 font-semibold">Preis</dt>
                            <dd className="mt-1 text-gray-500 lg:hidden">
                                <div className="flex items-center">
                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(bid.bidPrice)}
                                </div>
                            </dd>
                            <dt className="lg:hidden mt-4 font-semibold">Partner seit</dt>
                            <dd className="mt-1 text-gray-500 lg:hidden">
                                <div className="flex items-center">
                                    {new Date(bid.partnerSince).toLocaleDateString("de-DE", {day:"2-digit", month:"2-digit", year: "numeric"})}

                                </div>
                            </dd>

                            <dt className="lg:hidden mt-4 font-semibold">Empfehlung</dt>
                            <dd className="mt-1  text-gray-500 lg:hidden">
                                <div className="flex justify-start mx-auto">
                                    {
                                        bid.topRecomendation ? (<TrophyIcon className="text-accent-3 w-6 h-6 mr-2"/>) : <TrophyIcon className="text-gray-200 w-6 h-6 "/>
                                    }
                                    {
                                        bid.recomendation ? (<StarIcon className="text-accent-3 w-6 h-6 "/>) : <StarIcon className="text-gray-200 w-6 h-6 "/>
                                    }
                                </div>
                            </dd>
                            <dt className="lg:hidden mt-4 font-semibold">Premium Partner</dt>
                            <dd className="mt-1 text-gray-500 lg:hidden">
                                <div className="flex justify-start mx-auto">
                                    {
                                        bid.premiumPartner ? (<CheckBadgeIcon onMouseOver={()=>{}} className="text-accent-3 w-6 h-6 mr-2"/>) : <CheckBadgeIcon className="text-gray-200 w-6 h-6 "/>
                                    }
                                </div>
                            </dd>
   
                            <dt className="lg:hidden mt-4 font-semibold">Anzahl Umzüge</dt>
                            <dd className="mt-1 text-gray-500 lg:hidden">
                                <div className="flex items-center">
                                    {bid.numOfRelocations.toLocaleString()}
                                </div>
                            </dd>
                        </dl>
                        <dl className="font-semibold sm:hidden mt-10">
                            {bid.isSelected ? (
                                <div className="font-semibold text-accent-3 flex items-center">
                                    {selected === bid.bidId ?
                                        <span className="flex items-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-accent-3"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                      <path className="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg> Kontakt angefragt
                                </span> :
                                        <span className="flex items-center py-2">
                                    <CheckCircleIcon className=" w-4 h-4 mr-2"/> Kontakt angefragt
                                </span>
                                    }
                                </div>
                            ): (
                                <div onClick={()=>setSelected(bid.bidId)} className="text-accent-1 font-semibold flex items-center hover:text-accent-3">
                                    {selected === bid.bidId ?
                                        <span className="flex items-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-accent-3"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                      <path className="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg> Kontaktwunsch
                                </span> :
                                        <span className="flex items-center rounded py-2 px-3 border-2 border-accent-1 hover:text-accent-3 hover:border-accent-3">
                                    <ChatBubbleLeftRightIcon className=" w-4 h-4 mr-2"/> Kontaktwunsch
                                </span>
                                    }
                                </div>


                            )}
                        </dl>
                    </div>
                </td>
                <td onClick={()=>openDetail(bid.id)} className="hidden px-3 py-4 text-sm  lg:table-cell">
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(bid.bidPrice)}
                </td>
                <td onClick={()=>openDetail(bid.id)} className="hidden px-3 py-4 text-sm  lg:table-cell ">
                    <div className="hidden sm:flex items-center">
                        {[0, 1, 2, 3, 4].map((rating, index) => (
                            <div key={rating+index}>
                                {bewertungsText(bid.ratingAccount, rating)}
                            </div>
                        ))}
                        <span className="ml-2 font-semibold">{bid.ratingAccount}</span>
                        <span className="font-semibold">{!bid.ratingAccount.toString().split('.')[1] && ".0"}</span>
                    </div>
                </td>
                <td onClick={()=>openDetail(bid.id)} className="hidden text-center lg:table-cell px-3 py-4 text-sm">

                    {new Date(bid.partnerSince).toLocaleDateString("de-DE", {day:"2-digit", month:"2-digit", year: "numeric"})}

                </td>

                <td onClick={()=>openDetail(bid.id)} className="hidden lg:table-cell px-3 py-4 text-sm">
                    <div className="flex justify-center gap-2 mx-auto">
                        {
                            bid.topRecomendation ? <TrophyIcon className="text-accent-3 w-6 h-6 "/> : <TrophyIcon className="text-gray-200 w-6 h-6 "/>
                        }
                        {
                            bid.recomendation ? <StarIcon className="text-accent-3 w-6 h-6 "/> : <StarIcon className="text-gray-200 w-6 h-6 "/>
                        }

                        <div className="group relative flex items-center">
                            {
                                bid.avoidEmptyJourney ?
                                    <Recycling color="#009A00"/> :
                                    <Recycling color="rgb(229 231 235)"/>
                            }
                            <div className="hidden z-[1] group-hover:block absolute top-5 w-120 left-5 rounded-lg bg-white shadow-lg text-center font-semibold text-gray-500 text-xs p-3">
                                Durch dieses Leerfahrtenvermeidungsgebot profitieren Sie von niedrigeren Kosten, fixem Umzugstermin und umweltfreundlicheren Dienstleistungen. Entscheiden Sie sich für nachhaltigen und effizienten Service!
                            </div>

                        </div>
                    </div>

                </td>
                <td onClick={()=>openDetail(bid.id)} className="hidden lg:table-cell px-3 py-4 text-sm">
                    <div className=" flex justify-center gap-2 mx-auto">
                        <div className="group relative flex items-center">
                            {
                                bid.premiumPartner ? <CheckBadgeIcon className="group text-accent-3 w-6 h-6"/> : <CheckBadgeIcon className="text-gray-200 w-6 h-6"/>
                            }
                            <div className="hidden z-[1] group-hover:block absolute w-52 right-0 rounded-lg bg-white shadow-lg text-center font-semibold text-gray-500 text-xs p-3">Unsere Premium-Partner sind sorgfältig ausgewählt, um Ihnen ein Höchstmaß an Qualität, Service und Exklusivität zu bieten.</div>


                        </div>




                    </div>

                </td>
                <td onClick={()=>openDetail(bid.id)} className="hidden text-center lg:table-cell px-3 py-4 text-sm">
                    {bid.numOfRelocations}
                </td>
                {bid.isSelected ? (
                    <td  className="cursor-default min-w-[180px] w-[180px] hidden sm:flex sm:items-center lg:table-cell py-4 pl-3 pr-4 text-right text-sm font-medium">
                        <div className="font-semibold text-accent-3 flex items-center">
                            {selected === bid.bidId ?
                                <span className="flex items-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-accent-3"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                      <path className="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg> Kontakt angefragt
                                </span> :
                                <span className="flex items-center">
                                    <CheckCircleIcon className=" w-4 h-4 mr-2"/> Kontakt angefragt
                                </span>
                            }
                        </div>
                    </td>
                ): (
                    <td  className="cursor-pointer min-w-[180px] w-[180px] hidden sm:flex sm:items-center lg:table-cell py-4 pl-3 pr-4 text-right text-sm font-medium">
                        <div className="text-accent-1 font-semibold flex items-center hover:text-accent-3">
                            {selected === bid.bidId ?
                                <span className="flex items-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-accent-3"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                      <path className="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>  Kontaktwunsch
                                </span> :
                                <span onClick={()=>setSelected(bid.bidId)} className="flex items-center rounded py-2 px-3 border-2 border-accent-1 hover:text-accent-3 hover:border-accent-3">
                                    <ChatBubbleLeftRightIcon className=" w-4 h-4 mr-2"/> Kontaktwunsch
                                </span>
                            }
                        </div>
                    </td>
                )}
                <td onClick={()=>openDetail(bid.id)} className='hidden sm:flex sm:items-center lg:table-cell px-3 py-4 text-sm'>
                    <div className="flex justify-center mx-auto">
                            { 
                                <button onClick={()=>openDetail(bid.id)} className="bg-accent-3 py-2 px-3 text-gray-800 rounded font-semibold hover:bg-[#FFE066]">Details</button>
                            }
                    </div>
                </td>
            </tr>
        ))}
        </tbody>

}