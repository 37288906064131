/*
import {useIntl} from "react-intl";
*/
import {Popover} from "@headlessui/react";
import React from 'react'
import {ArrowLeftIcon, ArrowRightOnRectangleIcon, ListBulletIcon} from "@heroicons/react/20/solid";
import useToken from "../useToken";
import Items from "./Items";
import PreloaderProfile from "../Common/PreloaderProfile";


export default function Header({preloader, data,possessions, changeLocale, logout, totalVolume = null}) {
    const { token } = useToken();

    const opportunity = token.portal === "opportunity"

    return (
        <>
                <Popover as="header" className="lg:sticky top-0 z-[2] bg-accent-2">
                    {({ open }) => (
                        <>
                            <div className="mx-auto  px-4 sm:px-6  lg:px-8 pb-5">
                                <div >
                                    <div className="flex flex-col lg:flex-row gap-2 lg:gap-10 pt-4 pb-1 items-center">
                                        <span className="text-center max-w-[300px] mx-auto">
                                            <span className="sr-only">Umzugspreisvergleich</span>
                                            <img
                                                className="h-16 w-[400px] max-w-full"
                                                src="/img/logo.svg"
                                                alt="Umzugspreisvergleich"
                                            />
                                        </span>
                                        {!possessions && (<a className="
                                        border border-2 px-3 py-1.5 border-accent-1
                                        min-w-[180px] bg-white hover:text-white text-center rounded-md hover:bg-accent-1 text-accent-1
                                         hidden lg:flex flex-row items-center justify-center" href="/" target="_self" title="Zurück zur liste">
                                            <ArrowLeftIcon className="h-5 w-5 mr-2"/>
                                            Zurück zur Liste
                                        </a>)}
                                        <div className="flex gap-4 items-center w-full place-content-end">
                                        {possessions && (
                                            <div className="hidden lg:flex">
                                                <a href="/possessions" className="border border-2 px-3 py-1.5 border-accent-1
                                         bg-white hover:text-white text-center rounded-md hover:bg-accent-1 text-accent-1
                                         hidden lg:flex flex-row items-center">
                                                    <ListBulletIcon className="h-5 w-5 mr-4"/>

                                                    Ihre Güterliste
                                                </a>
                                            </div>
                                        )}


                                            {preloader ? (
                                                <PreloaderProfile/>
                                            ):
                                                !opportunity ? (
                                                <div className=" w-full lg:w-auto left-0 overflow-hidden rounded-lg bg-white shadow lg:rounded-none lg:bg-transparent lg:shadow-none">
                                                <div className="p-4 lg:py-0">
                                                <div>
                                                <div className="">
                                                <div className="group block flex-shrink-0">
                                                <div className="flex items-center">
                                                <div>
                                                <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-accent-1">
                                                <img alt={data?.name} src={data?.photo ? data.photo : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"} className="inline-block h-12 w-12 rounded-full"/>
                                                </span>
                                                </div>
                                                <div className="ml-3 flex flex-col">
                                                <p className="text-[1rem] font-medium text-gray-700 group-hover:text-gray-900">{data?.name}</p>

                                                <a target="_blank" rel="noreferrer"
                                                title="Emai Senden"
                                                href={`mailto:${data?.email}`}
                                                className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                            {data?.email}
                                                </a>
                                                <a target="_blank" rel="noreferrer"
                                                title="Anrufen"
                                                href={`tel:${data?.phone}`}
                                                className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                            {data?.phone}
                                                </a>
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                </div>

                                                ): (
                                                <div></div>
                                                )


                                            }

                                        <div className="hidden lg:flex">
                                            <button type="button" onClick={()=>logout()} className="flex p-2.5 text-gray-400 hover:text-gray-500">
                                                <span className="sr-only">Ausloggen</span>
                                                <ArrowRightOnRectangleIcon className="h-6 w-6" aria-hidden="true" /> <span className="ml-2 font-semibold">Ausloggen</span>
                                            </button>
                                        </div>
                                        </div>
                                        <div className="lg:hidden mt-3 flex justify-between items-center w-full">
                                            {possessions && (
                                                <a href="/possessions" className=" px-3 py-1 border-accent-1
                                                 text-center text-accent-1 rounded-lg font-semibold hover:bg-accent-1
                                                hover:text-white flex flex-row items-center">
                                                    <ListBulletIcon className="h-5 w-5 mr-4"/>
                                                    Ihre Güterliste
                                                </a>
                                             )}
                                            <div className={!possessions ? "w-full flex items-center justify-between" : "items-center flex justify-between"}>
                                                {!possessions&&(<a className="
                                                    border border-2 px-3 py-1.5 border-accent-1
                                                    w-[300] bg-white hover:text-white text-center rounded-md hover:bg-accent-1 text-accent-1
                                                      flex flex-row items-center justify-center" href="/" target="_self" title="Zurück zur liste">
                                                    <ArrowLeftIcon className="h-5 w-5 mr-2"/>
                                                    Zurück zur Liste
                                                </a>)}
                                                <button type="button" onClick={()=>logout()} className="flex p-2.5 text-gray-400 hover:text-gray-500">
                                                    <span className="sr-only">Log out</span>
                                                    <ArrowRightOnRectangleIcon className="h-6 w-6" aria-hidden="true" /> <span className="ml-2 font-semibold">Ausloggen</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               <Items preloader={preloader} opportunity={opportunity} data={data} totalVolume={totalVolume}/>
                            </div>
                        </>
                    )}
                </Popover>

          {/*  <img alt="Logo UPV" width="300px" src={ReactLogo}/>
            <h1>Header</h1>
            <h2>{locale}</h2>
            <div className="container mt">
                <br/>
                <input placeholder={intl.formatDate(Date.now(),{
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                })}/>

                <button onClick={()=>changeLocale("de")}>DE</button>
                <button onClick={()=>changeLocale("en")}>EN</button>
            </div>
            <h1>Header</h1>*/}
        </>
    )
}