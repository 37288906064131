import React from "react";
import {classNames} from "./Helper";

export default function PreloaderHead() {
    return (
        <div className="relative mt-4 flex items-center lg:justify-center lg:justify-between">
            <div className={classNames("lg:grid-cols-6 animated-pulse overflow-hidden rounded-lg bg-white shadow text-sm grid grid-cols-2 gap-6 lg:flex lg:justify-between w-full px-6 py-3.5 ")}>
                <div className="flex flex-col gap-4 ">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1 h-2 my-1 bg-slate-200 rounded w-full text-transparent">just a placeholder</span>
                    <span className="h-2 my-1 bg-slate-200 rounded w-full text-transparent">This is just a placeholder</span>
                </div>

                <div className="flex flex-col gap-4 ">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1 h-2 my-1 bg-slate-200 rounded w-full text-transparent">just a placeholder</span>
                    <span className="h-2 my-1 bg-slate-200 rounded w-full text-transparent">This is just a placeholder</span>
                </div>

                <div className="flex flex-col gap-4 ">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1 h-2 my-1 bg-slate-200 rounded w-full text-transparent">just a placeholder</span>
                    <span className="h-2 my-1 bg-slate-200 rounded w-full text-transparent">This is just a placeholder</span>
                </div>

                <div className="flex flex-col gap-4 ">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1 h-2 my-1 bg-slate-200 rounded w-full text-transparent">just a placeholder</span>
                    <span className="h-2 my-1 bg-slate-200 rounded w-full text-transparent">This is just a placeholder</span>
                </div>

                <div className="flex flex-col gap-4 ">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1 h-2 my-1 bg-slate-200 rounded w-full text-transparent">just a placeholder</span>
                    <span className="h-2 my-1 bg-slate-200 rounded w-full text-transparent">This is just a placeholder</span>
                </div>
            </div>
        </div>
    )
}