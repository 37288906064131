import React from "react";
import {Dialog} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {
    ArchiveBoxArrowDownIcon, PresentationChartLineIcon, ShoppingBagIcon, SparklesIcon,
    TruckIcon,
    WrenchScrewdriverIcon,
    UsersIcon
} from "@heroicons/react/20/solid";

export default function PreloaderDetail({accountType}) {
    return (
        <div className="animated-pulse flex h-full flex-col overflow-y-scroll bg-accent-2 py-6 shadow-xl">
            <div className="px-4 sm:px-6">
                <div className="flex items-start justify-between">
                    <Dialog.Title className="text-3xl font-bold leading-6 text-gray-900">
                        <div>{accountType === "lead"? 'Gebot Nr.': 'Auslieferung Nr.'}
                            <span className="ml-4 h-2 bg-slate-200 rounded w-auto text-transparent">gh</span>
                        </div>
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">

                        <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-1 focus:ring-offset-2"

                        >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                <section aria-labelledby="section-2-title">
                    {accountType==="lead" && <div className="overflow-hidden rounded-lg bg-white shadow mb-6 ">
                        <div className="p-6">
                            <div className="flex text-2xl font-semibold leading-6 text-gray-900 items-center">
                                Festpreis:  <span className="ml-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">dfgsdf</span>
                                <span className="ml-auto flex align-center">
                                    <span className="h-2 bg-slate-200 rounded col-span-2 text-transparent">dfgsdfgdfg</span>

                                </span>
                            </div>
                        </div>
                    </div>}
                    {accountType==="lead" && <div className="overflow-hidden rounded-lg bg-white shadow lg:stiky lg:to-0 mb-6">
                        <div className="px-6 pt-6">
                            <div>
                                <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                    <UsersIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                    <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                        Firmenpräsentation
                                    </h3>
                                </div>
                                <div className="mt-6 border-t border-gray-100">
                                    <div className="divide-y divide-gray-100 px-4 py-6">
                                        <span className="h-1 bg-slate-200 rounded col-span-2 text-transparent text-sm">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam dolor dolores, voluptatibus veniam a, ad, non odio blanditiis obcaecati facilis nihil commodi minus tempore unde nemo eius consequuntur. Suscipit, nisi.</span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="overflow-hidden rounded-lg bg-white shadow lg:stiky lg:to-0">
                        <div className="px-6 pt-6">
                            <div>
                                <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                    <PresentationChartLineIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                    <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                        Statistik</h3>
                                </div>
                                <div className="mt-6 border-t border-gray-100">
                                    <dl className="divide-y divide-gray-100">
                                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dt className="text-sm flex flex-col text-gray-700">
                                                {accountType==="lead" ? <span className="font-semibold">Partner</span>: <span className="font-semibold">Wann wurde die Firma gegründet?</span>}
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>
                                            </dt>
                                            <dd className="text-sm flex flex-col text-gray-700">
                                                {accountType==="lead" ? <span className="font-semibold">Bewertung</span>: <span className="font-semibold">Anzahl Mitarbeiter</span>}
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>

                                            </dd>
                                            <dt className="text-sm flex flex-col text-gray-700">
                                                {accountType==="lead" ? <span className="font-semibold">Gefahrene Strecke</span>: <span className="font-semibold">Anzahl eigene Fahrzeuge bis 3,5t</span>}
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>
                                            </dt>
                                        </div>
                                    </dl>
                                </div>
                                <div className=" border-t border-gray-100">
                                    <dl className="divide-y divide-gray-100">
                                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dd className="text-sm flex flex-col text-gray-700">
                                                {accountType==="lead" ? <span className="font-semibold">Transportiertes Volumen</span>: <span className="font-semibold">Anzahl eigene Fahrzeuge 3,5t bis 7,5t</span>}
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>
                                            </dd>
                                            <dt className="text-sm flex flex-col text-gray-700">
                                                {accountType==="lead" ? <span className="font-semibold">Anzahl Umzüge</span>: <span className="font-semibold">Anzahl eigene Fahrzeuge 7,5t bis 12t</span>}
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>
                                            </dt>
                                            <dd className="text-sm flex flex-col text-gray-700">
                                                {accountType==="lead" ? <span className="font-semibold">Verband</span>: <span className="font-semibold">Anzahl eigene Fahrzeuge ab 12t</span>}
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                {accountType==="opportunity" && <div className=" border-t border-gray-100">
                                    <dl className="divide-y divide-gray-100">
                                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dd className="text-sm flex flex-col text-gray-700">
                                                <span className="font-semibold">Google Rezession</span>
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>
                                            </dd>
                                            <dt className="text-sm flex flex-col text-gray-700">
                                                <span className="font-semibold">Trustpilot Rezession</span>
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>
                                            </dt>
                                            <dd className="text-sm flex flex-col text-gray-700">
                                                <span className="font-semibold">Umzugsfsirmencheck</span>
                                                <span className="h-2 bg-slate-200 rounded mt-2 w-12"></span>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {accountType==="opportunity" && <div className="overflow-hidden rounded-lg bg-white shadow lg:stiky lg:to-0 my-6">
                        <div className="px-6 pt-6">
                            <div>
                                <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                    <UsersIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                    <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                        Firmenpräsentation
                                    </h3>
                                </div>
                                <div className="mt-6 border-t border-gray-100 flex flex-col px-4 py-6 text-gray-700">
                                    <div className="divide-y divide-gray-100 font-semibold">
                                        Über uns
                                    </div>
                                    <span className="h-2 bg-slate-200 rounded col-span-2 text-transparent text-sm">gfdagdafdafdfadf</span>
                                </div>
                                <div className="border-t border-gray-100 flex flex-col px-4 py-6 text-gray-700">
                                    <div className="divide-y divide-gray-100 font-semibold">
                                        Wann oder wie haben wir unsere Firma gegründet?
                                    </div>
                                    <span className="h-2 bg-slate-200 rounded col-span-2 text-transparent text-sm">dfsfafasdfasfdfg</span>
                                </div>
                                <div className="border-t border-gray-100 flex flex-col px-4 py-6 text-gray-700">
                                    <div className="divide-y divide-gray-100 font-semibold">
                                        Was macht uns besonders?
                                    </div>
                                    <span className="h-2 bg-slate-200 rounded col-span-2 text-transparent text-sm">fakgjfghnfdksljgf</span>
                                </div>
                                <div className="border-t border-gray-100 flex flex-col px-4 py-6 text-gray-700">
                                    <div className="divide-y divide-gray-100 font-semibold">
                                        Was ist unser bester Tipp für Sie?
                                    </div>
                                    <span className="h-2 bg-slate-200 rounded col-span-2 text-transparent text-sm">gfsgfdsfgddsgadfa</span>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="overflow-hidden rounded-lg mt-6 bg-white shadow">
                        <div className="p-6">
                            <div>
                                <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                    <ShoppingBagIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                    <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                        Services</h3>
                                </div>
                                <div className="mt-6 border-t border-gray-100">
                                    <dl className="divide-y divide-gray-100">
                                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dt className="text-sm  text-gray-700">
                                                <div className="flex flex-row items-center">
                                                    <span><TruckIcon className="w-8 h-8 text-accent-1"/></span>
                                                    <div className="ml-3 flex flex-col text-md font-semibold">
                                                        <h3 className="text-lg font-semibold leading-7 text-gray-600">
                                                            Transport
                                                        </h3>
                                                        <span className="h-2 ml-1 bg-slate-200 rounded text-transparent">gg</span>
                                                    </div>
                                                </div>
                                                <ul className="mt-2 w-full">
                                                    <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">
                                                        fgdfgdfgfgdfgdfg
                                                    </li>

                                                    <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">
                                                        fgdfgdfgfgdfgdfg
                                                    </li>

                                                    <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">
                                                        fgdfgdfgfgdfgdfg
                                                    </li>
                                                </ul>
                                            </dt>
                                            <dt className="text-sm  text-gray-700">
                                                <div className="flex flex-row items-center">
                                                    <span><WrenchScrewdriverIcon className="w-8 h-8 text-accent-1"/></span>
                                                    <div className="ml-3 flex flex-row items-center text-md font-semibold">
                                                        <h3 className="text-lg font-semibold leading-7 text-gray-600">
                                                            Handwerk
                                                        </h3>
                                                        <span className="h-2 ml-1 bg-slate-200 rounded text-transparent">gg</span>
                                                    </div>

                                                </div>
                                                <ul className="mt-2 w-full">
                                                        <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">fgdfgdfgfgdfgdfg
                                                        </li>

                                                        <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">fgdfgdfgfgdfgdfg
                                                        </li>

                                                        <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">fgdfgdfgfgdfgdfg
                                                        </li>
                                                </ul>
                                            </dt>
                                            <dt className="text-sm text-gray-700">
                                                <div className="flex flex-row items-center">
                                                    <span><ArchiveBoxArrowDownIcon className="w-8 h-8 text-accent-1"/></span>
                                                    <div className="ml-3 flex flex-row text-md font-semibold">
                                                        <h3 className="text-lg font-semibold leading-7 text-gray-600">
                                                            Diverse
                                                        </h3>
                                                        <span className="h-2 ml-1 bg-slate-200 rounded text-transparent">gg</span>
                                                    </div>
                                                </div>
                                                <ul className="mt-2 w-full">
                                                    <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">
                                                        fgdfgdfgfgdfgdfg
                                                    </li>

                                                    <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">
                                                        fgdfgdfgfgdfgdfg
                                                    </li>

                                                    <li className="mb-4 h-2 bg-slate-200 rounded col-span-2 text-transparent">
                                                        fgdfgdfgfgdfgdfg
                                                    </li>
                                                </ul>
                                            </dt>
                                        </div>
                                    </dl>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="mt-6 overflow-hidden rounded-lg bg-white shadow">
                        <div className="p-6">
                            <div>
                                <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                    <SparklesIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                    <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                        Bewertungen

                                    </h3>
                                </div>
                                <div>
                                    <div className="mt-6 overflow-hidden rounded-lg bg-white shadow">
                                        <div>
                                            <div>
                                                <div className="not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                                                    <div className="relative rounded-xl overflow-auto">
                                                        <div className="relative w-full flex gap-6 snap-x snap-proximity overflow-x-auto pb-6">
                                                            <div className="snap-center shrink-0">
                                                                <div className="shrink-0 w-4 sm:w-48"></div>
                                                            </div>
                                                            <div className="snap-center shrink-0 first:pl-8 last:pr-8">
                                                                <div
                                                                    className="shrink-0 w-[350px] px-6 rounded-lg shadow-xl bg-white"
                                                                >

                                                                    <div className="mt-6 border-t border-gray-100">
                                                                        <dl className="divide-y divide-gray-100">
                                                                            <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                                <dt className="text-sm flex flex-col text-gray-700">
                                                                                    <span className="font-semibold">Datum</span>
                                                                                    <span className="h-2  bg-slate-200 rounded text-transparent">gg</span>
                                                                                </dt>

                                                                            </div>
                                                                        </dl>
                                                                    </div> <div className=" border-t border-gray-100">
                                                                    <dl className="divide-y divide-gray-100">
                                                                        <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                            <dt className="text-sm flex flex-col text-gray-700">
                                                                                <span className="font-semibold">Name</span>
                                                                                <span className="h-2  bg-slate-200 rounded text-transparent">gdfghdfghghg</span>
                                                                            </dt>
                                                                            <dd className="text-sm flex flex-col">
                                                                                <span className="font-semibold">Bewertung </span>

                                                                                <span className="h-2  bg-slate-200 rounded text-transparent">ghdfghg</span>

                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                                    <div className=" border-t border-gray-100">
                                                                        <dl className="divide-y divide-gray-100">
                                                                            <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                                <dt className="text-sm flex flex-col text-gray-700">
                                                                                    <span className="font-semibold">Gefahrene Strecke</span>
                                                                                    <span className="h-2 bg-slate-200 rounded text-transparent">gfhdfgg</span>
                                                                                </dt>
                                                                                <dd className="text-sm flex flex-col text-gray-700">
                                                                                    <span className="font-semibold">Transportiertes Volumen</span>
                                                                                    <span className="h-2 bg-slate-200 rounded text-transparent">ggh</span>
                                                                                </dd>
                                                                            </div>
                                                                        </dl>
                                                                    </div>
                                                                    <div className=" border-t border-gray-100">
                                                                        <dl className="divide-y divide-gray-100">
                                                                            <div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
                                                                                <dt className="text-sm flex flex-col text-gray-700">
                                                                                    <span className="h-2 bg-slate-200 rounded text-transparent">gg</span>
                                                                                </dt>
                                                                            </div>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="snap-center shrink-0">
                                                                <div className="shrink-0 w-4 sm:w-48"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*      {data?.ratings.length>0 && (
                        <div className="mt-6 overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-6">
                                <div>
                                    <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                        <h3 className="text-xl font-semibold leading-7 text-gray-900">Bewertungen
                                            <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 py-1 text-base font-medium text-accent-1">
                                                                        {Number(data?.ratings.length)}
                                                                    </span>
                                        </h3>
                                    </div>
                                    <div>
                                        <div className="mt-6 overflow-hidden rounded-lg bg-white shadow">
                                            <div>
                                                <div>
                                                    <div className="not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                                                        <div className="relative rounded-xl overflow-auto">
                                                            <div className="relative w-full flex gap-6 snap-x snap-proximity overflow-x-auto pb-6">
                                                                <div className="snap-center shrink-0">
                                                                    <div className="shrink-0 w-4 sm:w-48"></div>
                                                                </div>
                                                                {data?.ratings.map((rating)=>(
                                                                    <div key={rating.relocationCustomerName + rating.dateOfRating}
                                                                         className="snap-center shrink-0 first:pl-8 last:pr-8">
                                                                        <div
                                                                            className="shrink-0 w-[350px] px-6 rounded-lg shadow-xl bg-white"
                                                                        >

                                                                            <div className="mt-6 border-t border-gray-100">
                                                                                <dl className="divide-y divide-gray-100">
                                                                                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                                        <dt className="text-sm flex flex-col text-gray-700">
                                                                                            <span className="font-semibold">Datum</span>
                                                                                            <span>{new Date(rating.dateOfRating).toLocaleDateString("de-DE",{day:"2-digit", month:"2-digit", year: "numeric"})}</span>
                                                                                        </dt>

                                                                                    </div>
                                                                                </dl>
                                                                            </div> <div className=" border-t border-gray-100">
                                                                            <dl className="divide-y divide-gray-100">
                                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                                    <dt className="text-sm flex flex-col text-gray-700">
                                                                                        <span className="font-semibold">Name</span>
                                                                                        <span>{rating.relocationCustomerName}</span>
                                                                                    </dt>
                                                                                    <dd className="text-sm flex flex-col">
                                                                                        <span className="font-semibold">Bewertung </span>

                                                                                        {rating.rating && (
                                                                                            <span className="flex">
                                                                                                                    {[0, 1, 2, 3, 4].map((rate) => (
                                                                                                                        <StarIcon
                                                                                                                            key={rate}
                                                                                                                            className={classNames(
                                                                                                                                Number(rating.rating) > rate ? 'text-yellow-400' : 'text-gray-200',
                                                                                                                                'h-5 w-5 flex-shrink-0'
                                                                                                                            )}
                                                                                                                            aria-hidden="true"
                                                                                                                        />
                                                                                                                    ))}
                                                                                                                    </span>
                                                                                        )}
                                                                                    </dd>
                                                                                </div>
                                                                            </dl>
                                                                        </div>
                                                                            <div className=" border-t border-gray-100">
                                                                                <dl className="divide-y divide-gray-100">
                                                                                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                                        <dt className="text-sm flex flex-col text-gray-700">
                                                                                            <span className="font-semibold">Gefahrene Strecke</span>
                                                                                            <span>{rating.distance}</span>
                                                                                        </dt>
                                                                                        <dd className="text-sm flex flex-col text-gray-700">
                                                                                            <span className="font-semibold">Transportiertes Volumen</span>
                                                                                            <span>{rating.relocationVolume}</span>
                                                                                        </dd>
                                                                                    </div>
                                                                                </dl>
                                                                            </div>
                                                                            <div className=" border-t border-gray-100">
                                                                                <dl className="divide-y divide-gray-100">
                                                                                    <div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
                                                                                        <dt className="text-sm flex flex-col text-gray-700">
                                                                                            <span>{rating.annotation}</span>
                                                                                        </dt>
                                                                                    </div>
                                                                                </dl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="snap-center shrink-0">
                                                                    <div className="shrink-0 w-4 sm:w-48"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {data?.partnerImages.length>0 && (
                        <div className="mt-6 overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-6">
                                <div>
                                    <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                        <h3 className="text-xl font-semibold leading-7 mb-6 text-gray-900">
                                            Bilder
                                            <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 py-1 text-base font-medium text-accent-1">
                                                                        {Number(data?.partnerImages.length)}
                                                                    </span>
                                        </h3>

                                    </div>
                                    <div
                                        className="not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                                        <div className="relative rounded-xl overflow-auto">
                                            <div className="relative w-full flex gap-6 snap-x snap-proximity overflow-x-auto py-14">
                                                <div className="snap-center shrink-0">
                                                    <div className="shrink-0 w-4 sm:w-48"></div>
                                                </div>
                                                {data?.partnerImages.map((image)=>(
                                                    <div key={image.url}
                                                         className="snap-center shrink-0 first:pl-8 last:pr-8">
                                                        <img alt="Partner"
                                                             className="shrink-0 w-80 rounded-lg shadow-xl bg-white"
                                                             src={image.url}/>
                                                    </div>
                                                ))}
                                                <div className="snap-center shrink-0">
                                                    <div className="shrink-0 w-4 sm:w-48"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}*/}
                </section>
            </div>
        </div>
    )
}