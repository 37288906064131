import React from "react";

export default function PreloaderProfile() {
    return (
        <div className="animated-pulse w-full lg:w-auto left-0 overflow-hidden rounded-lg bg-white shadow lg:rounded-none lg:bg-transparent lg:shadow-none">
            <div className="p-4 lg:py-0">
                <div>
                    <div className="">
                        <div className="group block flex-shrink-0">
                            <div className="flex items-center">
                                <div>
                                                                         <span className="inline-flex h-12 w-12 bg-white items-center justify-center rounded-full bg-accent-1">
                                                                         </span>
                                </div>
                                <div className="ml-3 flex flex-col gap-2">
                                    <div className="text-[1rem] font-medium text-gray-700 group-hover:text-gray-900">
                                        <div
                                            className="w-full h-2 bg-slate-200 rounded text-transparent">Max Mustermann Coll</div>

                                    </div>

                                    <div


                                        className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                        <div
                                            className="w-full h-2 bg-slate-200 rounded text-transparent">maxmustsedfermann@gmail.com</div>

                                    </div>
                                    <div
                                        className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                        <div
                                            className="w-full h-2 bg-slate-200 rounded text-transparent">+0000 5qwedwqe11 0000</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}