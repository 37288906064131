import {IntlProvider} from "react-intl";
import Footer from './Footer';
import {useState} from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Login from './Login/Login';
import useToken from './useToken';
import Possessions from "./Possessions/Possessions";
import useData from "./useData";

const languages = {
    en: require('../lang/en.json'),
    de: require('../lang/de.json')
};

export default function App() {
    const { token, setToken, clearToken } = useToken();
    const {data, setCounter, preoloader, setPreloader} = useData(token, clearToken)
    const localStorageExist = localStorage.getItem("locale-ua")
    const [locale, setLocale] = useState(localStorageExist?localStorageExist:"en");
    const messages = languages[locale]

    const logout = () => {
        localStorage.removeItem("token")
        document.location.replace("/")
    }

    const handleChange = (e) => {
        setLocale(e);
        localStorage.setItem("locale-ua", e);
    };


    if(!token) {
        return (
            <IntlProvider messages={messages} locale={locale} defaultLocale="en">
                <div>
                    <Login setToken={setToken} />
                    {/*UM-091241<br/>asnagaraj@ymail.com<br/><br/>
                    AU-006016<br/>sabino84@gmail.com (no deliveries)<br/><br/>
                    AU-006336<br/>
                    a.schardin77@gmail.com<br/>*/}
                </div>
            </IntlProvider>
        )
    }
    return token && (
        <IntlProvider messages={messages} locale={locale} defaultLocale="en">
            <div className="min-h-full">
                <Router>
                    <Routes>
                        <Route path="/" element={<Dashboard preloader={preoloader} setPreloader={setPreloader} logout={logout} setCounter={setCounter} data={data} handleChangeLocale={handleChange} locale={locale} />} />

                        <Route path="/possessions" element={<Possessions preloader={preoloader} setPreloader={setPreloader} logout={logout} setCounter={setCounter} data={data} handleChangeLocale={handleChange} locale={locale} />} />

                    </Routes>
                </Router>


                <Footer />
            </div>
        </IntlProvider>
    )
}