
import React, {useEffect, useState} from "react";
import useToken from "../useToken";
import Header from "../Header/Header";

import Detail from "./Detail";
import DetailO from "./DetailO";
import Head from "./Tables/Head";
import Body from "./Tables/Body";
import BodyO from "./Tables/BodyO";
import PreloaderTable from "../Common/PreloaderTable";
import { useSearchParams} from 'react-router-dom';




export default function Dashboard({preloader, setPreloader, setCounter, data, handleChangeLocale, locale, logout}) {

    const { token } = useToken();
    const [selected, setSelected] = useState("")
    const [open, setOpen] = useState(false)
    const [params, setParams] = useSearchParams()
    const [id, setId] = useState('')

    useEffect( () => {
       setCounter(10)

    },[]);


    useEffect(()=>{
        if(token.portal === "lead"){
            setId(params.get('bidId'))
        }else if(token.portal === "opportunity"){
            setId(params.get('deliveryId'))
        }
        if(typeof data !== 'undefined' && id){
            if(token.portal === "lead"){
                if(data.bids.some((bid)=>bid.bidId === id)){
                    openDetail(id)
                }  
            }else if(token.portal === "opportunity"){
                if(data.deliveries.some((delivery)=>delivery.deliveryId === id)){
                    openDetail(id)
                }  
            }
       }
    },[data])

    const openDetail = (paramId) => {
        if(token.portal === "lead"){
            params.set('bidId', paramId)
        }else if(token.portal === "opportunity"){
            params.set('deliveryId', paramId)
        }
        setParams(params)
        setOpen(false)
        setSelected(paramId)
        setOpen(true)
    }

    const closeDetail = () => {
        if(token.portal === "lead"){
            params.delete('bidId')
        }else if(token.portal === "opportunity"){
            params.delete('deliveryId')
        }
        setParams(params)
        setOpen(false)
    }
    
    return  (
        <div>
            <Header preloader={preloader} data={data} possessions={true} logout={logout} changeLocale={handleChangeLocale} locale={locale} />
            {selected && 
                (token.portal === "lead" ? (<Detail open={open} closeDetail={closeDetail} selected={selected}/>) : 
                token.portal === "opportunity" && (<DetailO open={open} closeDetail={closeDetail} selected={selected}/>))
            }
            <main className=" pb-8 shadow-lg bg-white">
                <div className="mx-auto px-4 sm:px-6  lg:px-8">
                    <h1 className="sr-only">Kundenportal</h1>
                    <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                        <div className="grid grid-cols-1 gap-4 lg:col-span-3">
                            <section aria-labelledby="section-1-title">
                                <div className=" ">
                                    <div>
                                        <div>
                                            <div className="-mx-4 sm:-mx-0">
                                                {preloader ? <PreloaderTable/>:
                                                    token.portal === "lead" ? (
                                                            <table className=" min-w-full ">
                                                                <Head data={data} type="lead"/>
                                                                <Body setPreloader={setPreloader} setCounter={setCounter} data={data} openDetail={openDetail} />
                                                            </table>
                                                        ):
                                                        token.portal === "opportunity" && (
                                                            <table className="min-w-full border-separate border-spacing-y-4 lg:border-collapse lg:border-spacing-0 ">
                                                                <Head data={data} type="opportunity"/>
                                                                <BodyO data={data} openDetail={openDetail}/>
                                                            </table>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>


                    </div>
                </div>
            </main>
        </div>
    )
}





/*
import {FormattedMessage} from "react-intl";

export default function Content() {
  return (

      <>

        Im the content
        <h1><FormattedMessage id="test" /></h1>
      </>
  )
}*/
