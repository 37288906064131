import React from "react";

export default function Footer() {
  return (
      <footer>
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:flex sm:justify-between">
            <span className="block sm:inline">&copy; {new Date().getFullYear()} Umzugspreisvergleich</span>
              <span className="block sm:inline -ml-4">
                <a href="https://umzugspreisvergleich.de/impressum" target="_blank" className="text-gray-400">Impressum</a>
                <a href="https://umzugspreisvergleich.de/datenschutz" target="_blank" className="ml-2 text-gray-400">Datenschutzerklärung</a>
              </span>
              <span className="text-transparent">&copy; {new Date().getFullYear()} Umzugspreisvergleich</span>
          </div>

        </div>
      </footer>
  )
}