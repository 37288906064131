export const sumValues = (cadena) => {
    let suma = 0;

    cadena?.forEach(objeto => {
        const valores = Object.values(objeto);
        if (typeof valores[0] === "number") suma += valores[0];

    });

    return suma;
}

