import React from "react";
import {Disclosure} from "@headlessui/react";
import {FormattedMessage} from "react-intl";
import {MinusIcon, PlusIcon} from "@heroicons/react/20/solid";

export default function PreloaderPossessions() {
    return (
        <Disclosure defaultOpen={true} as="div" className="animated-pulse px-4 py-7 relative">
            <>
                <h3 className="z-10 -mx-4 -my-5 flow-root sticky top-0 px-4 shadow-md rounded-lg  bg-white">
                    <Disclosure.Button className="text-left flex w-full items-center justify-between py-6 text-gray-400 hover:text-gray-500">
                            <span className="animate-pulse font-medium text-accent-1 flex items-center">
                                <span className="h-2 bg-slate-200 rounded col-span-2 text-transparent">hola amigos </span>
                                <span className="text-center bg-accent-1 ml-3 rounded-md text-white min-w-[30px]  px-1.5 py-1 block">
                                    <span className="h-1 bg-slate-200 rounded col-span-2 text-transparent">ho</span>

                                </span>
                            </span>
                        <span className="ml-6 flex items-center">
                              <MinusIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                    </Disclosure.Button>
                </h3>
                <Disclosure.Panel className="pt-6">
                    <div>
                        <div>
                            <div className="animate-pulse border-b-2 border-gray-300 grid grid-cols-4 items-center justify-end gap-4 py-6 ">
                                <div className=" col-span-3 w-26 font-medium leading-6 text-white">
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>

                                </div>

                                <div className="col-span-1 mt-0 flex items-center justify-center gap-2">
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <MinusIcon className="h-6 w-6"/>
                                    </button>
                                    <div className="w-10 h-10 flex items-center font-semibold justify-center text-white">
                                        <div className="h-2 bg-slate-200 rounded col-span-2 text-transparent">hola</div>

                                    </div>
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <PlusIcon className="h-6 w-6"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="animate-pulse border-b-2 border-gray-300 grid grid-cols-4 items-center justify-end gap-4 py-6 ">
                                <div className=" col-span-3 w-26 font-medium leading-6 text-white">
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>

                                </div>

                                <div className="col-span-1 mt-0 flex items-center justify-center gap-2">
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <MinusIcon className="h-6 w-6"/>
                                    </button>
                                    <div className="w-10 h-10 flex items-center font-semibold justify-center text-white">
                                        <div className="h-2 bg-slate-200 rounded col-span-2 text-transparent">hola</div>

                                    </div>
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <PlusIcon className="h-6 w-6"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="animate-pulse border-b-2 border-gray-300 grid grid-cols-4 items-center justify-end gap-4 py-6 ">
                                <div className=" col-span-3 w-26 font-medium leading-6 text-white">
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>

                                </div>

                                <div className="col-span-1 mt-0 flex items-center justify-center gap-2">
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <MinusIcon className="h-6 w-6"/>
                                    </button>
                                    <div className="w-10 h-10 flex items-center font-semibold justify-center text-white">
                                        <div className="h-2 bg-slate-200 rounded col-span-2 text-transparent">hola</div>

                                    </div>
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <PlusIcon className="h-6 w-6"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="animate-pulse border-b-2 border-gray-300 grid grid-cols-4 items-center justify-end gap-4 py-6 ">
                                <div className=" col-span-3 w-26 font-medium leading-6 text-white">
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>

                                </div>

                                <div className="col-span-1 mt-0 flex items-center justify-center gap-2">
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <MinusIcon className="h-6 w-6"/>
                                    </button>
                                    <div className="w-10 h-10 flex items-center font-semibold justify-center text-white">
                                        <div className="h-2 bg-slate-200 rounded col-span-2 text-transparent">hola</div>

                                    </div>
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <PlusIcon className="h-6 w-6"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="animate-pulse border-b-2 border-gray-300 grid grid-cols-4 items-center justify-end gap-4 py-6 ">
                                <div className=" col-span-3 w-26 font-medium leading-6 text-white">
                                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>

                                </div>

                                <div className="col-span-1 mt-0 flex items-center justify-center gap-2">
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <MinusIcon className="h-6 w-6"/>
                                    </button>
                                    <div className="w-10 h-10 flex items-center font-semibold justify-center text-white">
                                        <div className="h-2 bg-slate-200 rounded col-span-2 text-transparent">hola</div>

                                    </div>
                                    <button type="button" className="text-white bg-accent-1 rounded-lg hover:bg-accent-3">
                                        <PlusIcon className="h-6 w-6"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Disclosure.Panel>
            </>
        </Disclosure>
    )
}