
export default function calculateVolume(dataInfo, setDataInfo, changeParent, changeKey, changeValue) {


    (dataInfo[changeParent].forEach((room)=>{
        if(Object.keys(room)[0] === changeKey){
            room[changeKey] = changeValue
        }
    }))

    var volume = 0

    const livingRoomObj = {
        livingRoom_wallUnit: 0.9,
        livingRoom_bookShelf: 0.5,
        livingRoom_buffetWithTop: 1.8,
        livingRoom_buffetWithoutTop: 1.5,
        livingRoom_closet: 0.8,
        livingRoom_tv: 0.4,
        livingRoom_tvTable: 0.2,
        livingRoom_mediaPlayer: 0.2,
        livingRoom_piano: 1.5,
        livingRoom_grandPiano: 2 ,       
        livingRoom_electricPiano: 0.5,
        livingRoom_couch: 0.4,
        livingRoom_armchair: 0.6,
        livingRoom_sideboard: 1.1,
        livingRoom_liteDesk: 1.5,
        livingRoom_massiveDesk: 1.8,
        livingRoom_table: 0.5,
        livingRoom_chair: 0.2,
        livingRoom_sewingMachine: 0.4,   
    }

    const studyObj = {
        study_filingCabinet: 0.9,
        study_bookShelf: 0.4,
        study_couch: 0.4,
        study_sideboard: 0.8,
        study_itDevice: 0.6,
        study_armchair: 0.6,
        study_copier: 1.5,
        study_desk: 1.5,
        study_cornerDesk: 1.4,
        study_chair: 0.3,
        study_table: 0.5
    }

    const bathroomObj = {
        bathroom_shoeCabinet: 0.4,
        bathroom_linenCloset: 0.2,
        bathroom_dresser: 0.7,
        bathroom_chair: 0.2,
        bathroom_wardrobe: 0.2,
        bathroom_ironingBoard: 0.1,
        bathroom_closet: 0.8,
        bathroom_washer: 0.5,
        bathroom_vacuumCleaner: 0.2,
        bathroom_cupboard: 0.2   
    }

    const garageObj = {
        garage_tire: 0.2,
        garage_planter: 0.1,
        garage_bike: 0.3,
        garage_tricycle: 0.1,
        garage_foldingTable: 0.2,
        garage_pingPongTable: 0.3,
        garage_ladder: 0.1,
        garage_garbageCan: 0.2,
        garage_mower: 0.3,
        garage_shelf: 0.4,
        garage_wheelBarrow: 0.4   
    }

    const boxesObj = {
        boxes_presentBox: 0.1,
        boxes_orderedStandardBox: 0.1,
        boxes_orderedBookBox: 0.2,
        boxes_orderedClothesBox: 0.6,
        boxes_orderedDishBox: 0.2   
    }

    const basementObj = {
        basement_stroller: 0.5,
        basement_suitcase: 0.1,
        basement_shelf: 0.4,
        basement_sled: 0.2,
        basement_ski: 0.2,
        basement_workBench: 0.4,
        basement_toolCabinet: 0.2,
        basement_toolBox: 0.1,
    }

    const nurseryObj = {
        nursery_singleBed: 1.0,
        nursery_childBed: 0.5,
        nursery_bedsideTable: 0.2,
        nursery_bedding: 0.3,
        nursery_dismantlableCloset: 0.8,
        nursery_compactCloset: 1.6,
        nursery_dresser: 0.7,
        nursery_table: 0.5,
        nursery_toyBox: 0.4,
        nursery_couch: 0.4,
        nursery_desk: 0.7,
        nursery_chair: 0.2,
        nursery_playPen: 0.1,
        nursery_loftBed: 1.5,
        nursery_wallUnit: 0.2,
        nursery_bookShelf: 0.2
    }

    const kitchenObj = {
        kitchen_counterTop: 1.0,
        kitchen_broomCabinet: 0.6,
        kitchen_buffetWithTop: 1.8,
        kitchen_buffetWithoutTop: 1.5,
        kitchen_kitchenBench: 0.2,
        kitchen_smallFridge: 0.5,
        kitchen_largeFridge: 1.0,
        kitchen_freezer: 1.0,
        kitchen_stove: 0.5,
        kitchen_dishWasher: 0.5,
        kitchen_microwave: 0.2,
        kitchen_cabinet: 0.5,
        kitchen_liquorCabinet: 0.8,
        kitchen_sideboard: 1.0,
        kitchen_table: 0.6,
        kitchen_chair: 0.2
    }

    const bedroomObj = {
        bedroom_singleBed: 1.0,
        bedroom_doubleBed: 2.0,
        bedroom_bedsideTable: 0.2,
        bedroom_bedding: 0.3,
        bedroom_dismantlableCloset: 0.8,
        bedroom_compactCloset: 1.6,
        bedroom_slidingDoorCloset: 1.8,
        bedroom_dresser: 0.7,
        bedroom_chair: 0.25,
        bedroom_tvTable: 0.2,
        bedroom_tv: 0.4,
    }

    const miscObj = {
        misc_fitnessEquipment: 0.4,
        misc_mirror: 0.2,
        misc_plantSmall: 0.4,
        misc_plantLarge: 0.8,
        misc_carpet: 0.3,
        misc_pictures: 0.1,
        misc_ceilingLamp: 0.2,
        misc_floorLamp: 0.2
    }

    const calculateRoomVolume = (room, roomObj)=>{
        (dataInfo[room].forEach((el)=>{
            if(Object.hasOwn(roomObj, Object.keys(el)[0])){
                volume += Object.values(el)[0] * roomObj[Object.keys(el)[0]]
            }
        }))
    }

    calculateRoomVolume('livingRoom', livingRoomObj)
    calculateRoomVolume('study', studyObj)
    calculateRoomVolume('bathroom', bathroomObj)
    calculateRoomVolume('garage', garageObj)
    calculateRoomVolume('boxes', boxesObj)
    calculateRoomVolume('basement', basementObj)
    calculateRoomVolume('nursery', nurseryObj)
    calculateRoomVolume('kitchen', kitchenObj)
    calculateRoomVolume('bedroom', bedroomObj)
    calculateRoomVolume('misc', miscObj)


    dataInfo.totalVolume = Math.ceil(volume)
    setDataInfo(dataInfo)
    
}