import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import useToken from "../useToken";
import {
    ArchiveBoxArrowDownIcon, CameraIcon, PresentationChartLineIcon, ShoppingBagIcon, SparklesIcon,
    TruckIcon,
    WrenchScrewdriverIcon,
    UsersIcon,
    ArrowTopRightOnSquareIcon
} from "@heroicons/react/20/solid";
import PreloaderDetail from "../Common/PreloaderDetail";
import {bewertungsText} from "../Common/Helper";


export default function DetailO({selected, closeDetail, open}) {
    const [data,setData] = useState();
    const { token } = useToken();
    const [dataPreload,setDataPreload]  = useState(false);

    useEffect(() => {
        setDataPreload(true)

        open === true && fetch(`${process.env.REACT_APP_API_BASE_URL}/deliveryDetails?deliveryId=${selected}&token=${token.token}`, {
            method: 'GET',
            headers: {
                'x-api-key':process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            }
        }).then((data)=>{
            return data.status === 403 ? localStorage.removeItem("token") : data.json()
        }).then((newData)=>{
            setData(newData)
            setDataPreload(false)
        });



    }, [open]);


    return (
       <Transition.Root show={open} as={Fragment}>
           <Dialog as="div" className="relative z-10" onClose={closeDetail}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
                </Transition.Child>

               <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-6xl">
                                    {dataPreload ? (<PreloaderDetail accountType="opportunity"/>) : (
                                    <div className="flex h-full flex-col overflow-y-scroll bg-accent-2 py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-3xl font-bold leading-6 text-gray-900">
                                                    <span>Auslieferung Nr. <span className="ml-2">{data?.deliveryNumber? data.deliveryNumber:"TODO Backend"}</span></span>

                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">

                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-1 focus:ring-offset-2"
                                                        onClick={() => closeDetail()}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <section aria-labelledby="section-2-title">
                                                {data?.partnerImages?.length>0 && (
                                                    <div className="my-6 overflow-hidden rounded-lg bg-white shadow">
                                                        <div className="p-6">
                                                            <div>
                                                                <div className="px-4 sm:px-0 flex flex-row items-center mb-6 lg:flex-row">
                                                                    <CameraIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                                                    <h3 className="text-2xl font-semibold leading-7 text-gray-900">
                                                                        Bilder

                                                                    </h3>
                                                                    <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 text-base font-medium text-accent-1">
                                                                        {Number(data?.partnerImages?.length)}
                                                                    </span>

                                                                </div>
                                                                <div
                                                                    className="not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                                                                    <div className="relative rounded-xl overflow-auto">
                                                                        <div className="relative w-full flex gap-6 snap-x snap-proximity overflow-x-auto py-14">
                                                                            <div className="snap-center shrink-0">
                                                                                <div className="shrink-0 w-4 sm:w-48"></div>
                                                                            </div>
                                                                            {data?.partnerImages.map((image)=>(
                                                                                <div key={image.url}
                                                                                     className="snap-center shrink-0 first:pl-8 last:pr-8">
                                                                                    <img alt="Partner"
                                                                                         className="shrink-0 w-80 rounded-lg shadow-xl bg-white"
                                                                                         src={image.url}/>
                                                                                </div>
                                                                            ))}
                                                                            <div className="snap-center shrink-0">
                                                                                <div className="shrink-0 w-4 sm:w-48"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="overflow-hidden rounded-lg bg-white shadow lg:stiky lg:to-0 mb-6">
                                                    <div className="px-6 pt-6">
                                                        <div>
                                                            <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                                                <PresentationChartLineIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                                                <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                                                Statistik</h3>
                                                            </div>
                                                            <div className="mt-6 border-t border-gray-100">
                                                                <dl className="divide-y divide-gray-100">
                                                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                        <dd className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Wann wurde die Firma gegründet?</span>
                                                                            {data?.foundingDate && new Date(data?.foundingDate).toLocaleDateString("de-DE",{day:"2-digit", month:"2-digit", year: "numeric"})}
                                                                        </dd>
                                                                        <dt className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Anzahl Mitarbeiter</span>
                                                                            <span>{data?.numberEmployees?.toLocaleString()}</span>
                                                                        </dt>
                                                                        <dd className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Anzahl eigene Fahrzeuge bis 3,5t</span>
                                                                            <span>{data?.numberOwnVehiclesUpTo3p5t}</span>
                                                                        </dd>
                                                                    </div>
                                                                </dl>
                                                            </div>
                                                            <div className="border-t border-gray-100">
                                                                <dl className="divide-y divide-gray-100">
                                                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                        <dd className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Anzahl eigene Fahrzeuge 3,5t bis 7,5t</span>
                                                                            <span>{data?.numberOwnVehiclesBetween3p5To7p5t?.toLocaleString()}</span>
                                                                        </dd>
                                                                        <dt className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Anzahl eigene Fahrzeuge 7,5t bis 12t</span>
                                                                            <span>{data?.numberOwnVehiclesBetween7p5To12t?.toLocaleString()}</span>
                                                                        </dt>
                                                                        <dd className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Anzahl eigene Fahrzeuge ab 12t</span>
                                                                            <span>{data?.numberOwnVehiclesOver12t}</span>
                                                                        </dd>
                                                                    </div>
                                                                </dl>
                                                            </div>
                                                            <div className="border-t border-gray-100">
                                                                <dl className="divide-y divide-gray-100">
                                                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                        <dd className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Google Rezension</span>
                                                                                {data?.google && <a rel='noreferrer' target="_blank" href={data?.google} className="flex items-center w-fit gap-1 my-3 px-2 py-1 rounded-md border-2 border-accent-1 text-accent-1 hover:text-accent-3 hover:border-accent-3">Jetzt ansehen<ArrowTopRightOnSquareIcon className="w-4 h-4"/></a>}
                                                                        </dd>
                                                                        <dt className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Trustpilot Rezension</span>
                                                                            {data?.trustPilot && <a rel='noreferrer' target="_blank" href={data?.trustPilot} className="flex items-center w-fit gap-1 my-3 px-2 py-1 rounded-md border-2 border-accent-1 text-accent-1 hover:text-accent-3 hover:border-accent-3">Jetzt ansehen<ArrowTopRightOnSquareIcon className="w-4 h-4"/></a>}
                                                                        </dt>
                                                                        <dd className="text-sm flex flex-col text-gray-700">
                                                                            <span className="font-semibold">Umzugsfirmencheck</span>
                                                                            {data?.umzugsfirmencheck && <a rel='noreferrer' target="_blank" href={data?.umzugsfirmencheck} className="flex items-center w-fit gap-1 my-3 px-2 py-1 rounded-md border-2 border-accent-1 text-accent-1 hover:text-accent-3 hover:border-accent-3">Jetzt ansehen<ArrowTopRightOnSquareIcon className="w-4 h-4"/></a>}
                                                                        </dd>
                                                                    </div>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {data?.companyPresentation && <div className="overflow-hidden rounded-lg bg-white shadow lg:stiky lg:to-0 mb-6">
                                                    <div className="px-6 pt-6">
                                                        <div>
                                                            <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                                                <UsersIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                                                <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                                                    Firmenpräsentation
                                                                </h3>
                                                            </div>
                                                            {data?.companyPresentation?.aboutCompanyLM && <div className="mt-6 border-t border-gray-100 flex flex-col px-4 py-6 text-gray-700">
                                                                <div className="divide-y divide-gray-100 font-semibold">
                                                                    Über uns
                                                                </div>
                                                                <span>{data?.companyPresentation?.aboutCompanyLM}</span>
                                                            </div>}
                                                            {data?.companyPresentation?.whenOrHowWasTheFoundation && <div className="border-t border-gray-100 flex flex-col px-4 py-6 text-gray-700">
                                                                <div className="divide-y divide-gray-100 font-semibold">
                                                                    Wann oder wie haben wir unsere Firma gegründet?
                                                                </div>
                                                                <span>{data?.companyPresentation?.whenOrHowWasTheFoundation}</span>
                                                            </div>}
                                                            {data?.companyPresentation?.whatMakesUsSpecial && <div className="border-t border-gray-100 flex flex-col px-4 py-6 text-gray-700">
                                                                <div className="divide-y divide-gray-100 font-semibold">
                                                                    Was macht uns besonders?
                                                                </div>
                                                                <span>{data?.companyPresentation?.whatMakesUsSpecial}</span>
                                                            </div>}
                                                            {data?.companyPresentation?.whatIsOurSpecialHintForYou && <div className="border-t border-gray-100 flex flex-col px-4 py-6 text-gray-700">
                                                                <div className="divide-y divide-gray-100 font-semibold">
                                                                    Was ist unser bester Tipp für Sie?
                                                                </div>
                                                                <span>{data?.companyPresentation?.whatIsOurSpecialHintForYou}</span>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>}
                                                <div className="overflow-hidden rounded-lg mt-6 bg-white shadow">
                                                    <div className="p-6">
                                                        <div>
                                                            <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                                                <ShoppingBagIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                                                <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                                                    Services</h3>
                                                            </div>
                                                            <div className="mt-6 border-t border-gray-100">
                                                                <dl className="divide-y divide-gray-100">
                                                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                                        <dt className="text-sm  text-gray-700">
                                                                            <div className="flex flex-row items-center">
                                                                                <span><TruckIcon className="w-8 h-8 text-accent-1"/></span>
                                                                                <div className="ml-1 flex flex-row text-md font-semibold">
                                                                                    <h3 className="text-lg font-semibold leading-7 text-gray-600">
                                                                                        Transport
                                                                                    </h3>
                                                                                    {data?.transport?.length ? (
                                                                                        <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 text-base font-medium text-accent-1">
                                                                                                {data?.transport?.length}
                                                                                            </span>
                                                                                    ): <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 text-base font-medium text-accent-1">
                                                                                               0
                                                                                            </span>}
                                                                                </div>
                                                                            </div>
                                                                            <ul className="mt-6 w-full">
                                                                                {data?.transport?.length>0 && data.transport.map((item)=>(<li className="mb-2" key={item}>{item}</li>))}
                                                                            </ul>
                                                                        </dt>
                                                                        <dt className="text-sm  text-gray-700">
                                                                            <div className="flex flex-row items-center">
                                                                                <span><WrenchScrewdriverIcon className="w-8 h-8 text-accent-1"/></span>
                                                                                <div className="ml-1 flex flex-row text-md font-semibold">
                                                                                    <h3 className="text-lg font-semibold leading-7 text-gray-600">
                                                                                        Handwerk
                                                                                    </h3>
                                                                                    {data?.service?.length ? (
                                                                                        <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 text-base font-medium text-accent-1">
                                                                                                {data?.service?.length}
                                                                                        </span>
                                                                                    ): <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 text-base font-medium text-accent-1">
                                                                                        0
                                                                                        </span>}
                                                                                </div>

                                                                            </div>
                                                                            <ul className="mt-6 w-full">
                                                                                {data?.service?.length>0 && data.service.map((item)=>(<li className="mb-2" key={item}>{item}</li>))}
                                                                            </ul>
                                                                        </dt>
                                                                        <dt className="text-sm text-gray-700">
                                                                            <div className="flex flex-row items-center">
                                                                                <span><ArchiveBoxArrowDownIcon className="w-8 h-8 text-accent-1"/></span>
                                                                                <div className="ml-1 flex items-center flex-row text-md font-semibold">
                                                                                    <h3 className="text-lg font-semibold leading-7 text-gray-600">
                                                                                        Diverse
                                                                                    </h3>
                                                                                    {data?.diverse?.length ? (
                                                                                        <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 text-base font-medium text-accent-1">
                                                                                                {data?.diverse?.length}
                                                                                        </span>
                                                                                    ): <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-3 text-base font-medium text-accent-1">
                                                                                        0
                                                                                        </span>}
                                                                                </div>
                                                                            </div>
                                                                            <ul className="mt-6 w-full">
                                                                                {data?.diverse?.length>0 && data.diverse.map((item)=>(<li className="mb-2" key={item}>{item}</li>))}
                                                                            </ul>
                                                                        </dt>
                                                                    </div>
                                                                </dl>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {data?.ratings?.length>0 && (
                                                    <div className="mt-6 overflow-hidden rounded-lg bg-white shadow">
                                                        <div className="p-6">
                                                            <div>
                                                                <div className="px-4 sm:px-0 flex flex-col lg:flex-row">
                                                                    <SparklesIcon className="w-7 h-7 mr-2 text-accent-1"/>
                                                                    <h3 className="text-2xl font-semibold leading-7 mb-6 text-gray-900">
                                                                        Bewertungen
                                                                    </h3>
                                                                    <span className="inline-flex items-center rounded-md bg-accent-2 px-2 ml-1 py-1 text-base font-medium text-accent-1">
                                                                        {Number(data?.ratings?.length)}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <div className="mt-6 overflow-hidden rounded-lg bg-white shadow">
                                                                        <div>
                                                                            <div>
                                                                                <div className="not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                                                                                    <div className="relative rounded-xl overflow-auto">
                                                                                        <div className="relative w-full flex gap-6 snap-x snap-proximity overflow-x-auto pb-6">
                                                                                            <div className="snap-center shrink-0">
                                                                                                <div className="shrink-0 w-4 sm:w-48"></div>
                                                                                            </div>
                                                                                            {data?.ratings.map((rating)=>(
                                                                                                <div key={rating.relocationCustomerName + rating.dateOfRating}
                                                                                                     className="snap-center shrink-0 first:pl-8 last:pr-8">
                                                                                                    <div
                                                                                                        className="shrink-0 w-[350px] px-6 rounded-lg shadow-xl bg-white"
                                                                                                    >
                                                                                                        <div className="mt-6 border-t border-gray-100">
                                                                                                            <dl className="divide-y divide-gray-100">
                                                                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                                                                    <dt className="text-sm flex flex-col text-gray-700">
                                                                                                                        <span className="font-semibold">Datum</span>
                                                                                                                        <span>{new Date(rating.dateOfRating).toLocaleDateString("de-DE",{day:"2-digit", month:"2-digit", year: "numeric"})}</span>
                                                                                                                    </dt>

                                                                                                                </div>
                                                                                                            </dl>
                                                                                                        </div> <div className=" border-t border-gray-100">
                                                                                                        <dl className="divide-y divide-gray-100">
                                                                                                            <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                                                                <dt className="text-sm flex flex-col text-gray-700">
                                                                                                                    <span className="font-semibold">Name</span>
                                                                                                                    <span>{rating.relocationCustomerName}</span>
                                                                                                                </dt>
                                                                                                                <dd className="text-sm flex flex-col">
                                                                                                                    <span className="font-semibold">Bewertung </span>

                                                                                                                    {rating.rating && (
                                                                                                                        <span className="flex">

                                                                                                                                  {[0, 1, 2, 3, 4].map((item, index) => (
                                                                                                                                      <div key={rating+index}>
                                                                                                                                          {bewertungsText(rating.rating, item)}
                                                                                                                                      </div>
                                                                                                                                  ))}
                                                                                                                            <span className="ml-2 font-semibold">{rating.rating}</span>
                                                                                                                            <span className="font-semibold">{!rating.rating.toString().split('.')[1] && ".0"}</span>
                                                                                                                    </span>
                                                                                                                    )}
                                                                                                                </dd>
                                                                                                            </div>
                                                                                                        </dl>
                                                                                                    </div>
                                                                                                        <div className=" border-t border-gray-100">
                                                                                                            <dl className="divide-y divide-gray-100">
                                                                                                                <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                                                                                                                    <dt className="text-sm flex flex-col text-gray-700">
                                                                                                                        <span className="font-semibold">Gefahrene Strecke</span>
                                                                                                                        <span>{rating.distance.toLocaleString()}</span>
                                                                                                                    </dt>
                                                                                                                    <dd className="text-sm flex flex-col text-gray-700">
                                                                                                                        <span className="font-semibold">Transportiertes Volumen</span>
                                                                                                                        <span>{rating.relocationVolume.toLocaleString()}</span>
                                                                                                                    </dd>
                                                                                                                </div>
                                                                                                            </dl>
                                                                                                        </div>
                                                                                                        <div className=" border-t border-gray-100">
                                                                                                            <dl className="divide-y divide-gray-100">
                                                                                                                <div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
                                                                                                                    <dt className="text-sm flex flex-col text-gray-700">
                                                                                                                        <span>{rating.annotation}</span>
                                                                                                                    </dt>
                                                                                                                </div>
                                                                                                            </dl>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                            <div className="snap-center shrink-0">
                                                                                                <div className="shrink-0 w-4 sm:w-48"></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </section>
                                        </div>
                                   </div> 
                                )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}