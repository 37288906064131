import {StarIcon} from "@heroicons/react/20/solid";
import React from "react";

export const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

export const bewertungsText = (bewertung, current) => {
    const letzteZiffer = Number(bewertung.toString().split('.')[1]); // Holt die letzte Ziffer nach dem Dezimalpunkt
    const isInt = !letzteZiffer
    const starId = current + 1

    const fullStar = <StarIcon
        key={current}
        className='text-yellow-400 h-5 w-5 flex-shrink-0'
        aria-hidden="true"
    />

    const halfStar = <svg className="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
    >
        <path
            fill="#E5E7EB"
            fillRule="evenodd"
            d="M10.308 3.185c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425l-4.627-2.826-4.627 2.826c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005v-.001z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#FACC15"
            fillRule="evenodd"
            d="M10.356 3.145a1.293 1.293 0 011.212-.808c.103 0 .06 3.311.054 3.703-.007.5-.054 12.249-.054 12.249l-4.627 2.826c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005v-.001z"
            clipRule="evenodd"
        ></path>
    </svg>


    const emptyStar = <StarIcon
        key={current}
        className='text-gray-200 h-5 w-5 flex-shrink-0'
        aria-hidden="true"
    />;

    if (letzteZiffer && (parseInt(bewertung)+1 === starId))  {
        return  halfStar

    } else {
        return parseInt(bewertung) < starId ? emptyStar : fullStar
    }

}

export const currencyFormatter = new Intl.NumberFormat('de-DE', {
style: 'currency',
currency: 'EUR'
})
