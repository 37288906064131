import {InformationCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";
import { useState } from "react";
import { classNames } from "../../Common/Helper";

export default function Head({data, type}) {

    const [showHint, setShowHint] = useState(true)

    return (<thead className="sticky top-0 bg-white  divide-y">
    {(Array.isArray(data?.deliveries) && data.deliveries.length) || (Array.isArray(data?.bids) && data.bids.length) ? (
            <tr className="">
                <th
                    scope="col"
                    className="h-20 z-10 hidden px-3 py-3.5 text-center text-sm font-bold text-gray-900 lg:table-cell"
                >
                    Nr.
                </th>


                <th scope="col" className="h-20 hidden lg:table-cell z-10 py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 lg:pl-3">
                    <span className="hidden lg:block">Datum</span>
                </th>

                {type==="lead" && (<th
                    scope="col"
                    className="h-20 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                    Termin
                </th>)}


                <th scope="col"
                    className="h-20 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                    {type==="lead"?"Preis":"Name Spedition"}
                </th>
                <th scope="col" className="h-20 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                    {type==="lead"?"Bewertung":"Mail Spedition"}
                </th>

                {type==="opportunity" && (<th scope="col" className="h-20 hidden lg:table-cell z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Telefon Spedition
                </th>)}
                {type==="opportunity" && (<th scope="col" className="h-20 hidden lg:table-cell z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Adresse Spedition
                </th>)}

                {type==="lead" && (<th scope="col" className="h-20 hidden lg:table-cell z-10 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Partner seit
                </th>)}



                {type==="lead"&&(
                    <th scope="col" className="h-20 hidden lg:table-cell z-10 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Empfehlung
                    </th>
                )}
                {type==="lead"&&(
                    <th scope="col" className="h-20 hidden lg:table-cell z-10 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Premium Partner
                    </th>
                )}
                {type==="lead"&&(
                    <th scope="col" className="h-20 hidden lg:table-cell z-10 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Anzahl Umzüge
                    </th>
                )}

                {type==="lead"&&(
                    <th scope="col" className="relative h-20 hidden lg:table-cell z-10 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        <span className="flex items-center justify-center gap-3">Aktion <InformationCircleIcon onClick={()=>{setShowHint(!showHint)}} className={classNames(showHint ? "text-accent-3" : "text-accent-1", "hover:cursor-pointer hover:text-accent-3 h-5")}   /></span>
                        {showHint && <div className="absolute w-52 right-16 rounded-lg bg-white shadow-lg text-gray-500 text-xs p-3"><XMarkIcon onClick={()=>{setShowHint(false)}} className="h-5 w-5 absolute right-1 hover:cursor-pointer hover:text-accent-3" aria-hidden="true" />Klicken Sie auf “Kontaktwunsch”, um die Kontaktdaten der ausgewählten Firma zu erhalten.</div>}
                    </th>
                )}


            </tr>
    ) : (<tr><td></td></tr>)}
    </thead>)
}