import {useEffect, useState} from 'react';

export default function useData(token, clearToken) {
    const [preoloader, setPreloader] = useState(false);
    const [data, setData] = useState();
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        setPreloader(true)
        if (token?.portal) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/${token.portal === "lead" ? "bidsOverview" : "deliveriesOverview"}?token=${token.token}`, {
                method: 'GET',
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Invalid response');
                    }
                })
                .then((data) => {
                    setData(data);
                    setPreloader(false)
                })
                .catch(() => clearToken());
        }
    },[counter, token]);


    return {data, setCounter, preoloader, setPreloader}
}