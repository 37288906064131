import Header from "../Header/Header";
import React, {useEffect, useState} from "react";
import useToken from "../useToken";
import {useForm} from "react-hook-form";
import Notification from "../Common/Notification";
import {mainPossessions, secondaryPossessions, sidebarPossessions} from "./Fields"
import List from "./List"
import PreloaderPossessions from "../Common/PreloaderPossessions";


function getTotalValues(list, value) {
    let totalValues = 0;
    let obj = {}
    list.forEach(option => {
        const counterKey = Object.keys(value).filter(key => key.includes(option.key));
        let totalCounterKey = 0;
        counterKey.forEach(key => {
            totalCounterKey += typeof value[key] === "number" ? value[key] : 0;
            obj[option.key] = totalCounterKey
        });
        totalValues += totalCounterKey;
    });
    return obj;
}


export default function Possessions({preloader, setCounter, locale, logout, handleChangeLocale, data}) {
    const {token} = useToken();
    const [preloadList, setPreloadList] = useState();
    const [dataInfo, setDataInfo] = useState();
    const [counterPossessions, setCounterPossessions] = useState();
    const [counterSPossessions, setCounterSPossessions] = useState();
    const [counterSidePossessions, setCounterSidePossessions] = useState();
    const [sendButton, setSendButton] = useState(false);
    const [preloadButton, setPreLoadButton] = useState(false);
    const { register, handleSubmit , setValue, watch} = useForm();
    const [showNotification, setShowNotification] = useState(false)

    useEffect( () => {
        setCounter(10)

    },[]);


    const onSubmit = data => {
       setPreLoadButton(true)
        fetch(`${process.env.REACT_APP_API_BASE_URL}/postPossessions?token=${token.token}`, {
            method: 'POST',
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            setPreLoadButton(false)
            setSendButton(false)
            return response.status === 200 && setShowNotification(true)
        }).catch(reason => console.log("ERROR: "+reason))

    };

    useEffect(() => {
        setPreloadList(true)
         fetch(`${process.env.REACT_APP_API_BASE_URL}/getPossessionsDetails?token=${token.token}`, {
            method: 'GET',
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
             return response.status === 200 && response.body ? response.json() : {}
         }).then((data) => {
             setPreloadList(false)
             return setDataInfo(data)
         }).catch((error)=>{
             localStorage.removeItem("token")
            window.location.replace("/")
        })
    },[]);




    useEffect(() => {
        const subscription = watch((value, { name, type }) =>{
            setCounterPossessions(getTotalValues(mainPossessions, value))
            setCounterSPossessions(getTotalValues(secondaryPossessions, value))
            setCounterSidePossessions(getTotalValues(sidebarPossessions, value))
        }



    )
        return () => subscription.unsubscribe()
    }, [watch])

    return(
        <div>
            <Notification show={showNotification} setShow={setShowNotification}/>
            <div className="lg:sticky top-0 z-10">

                    <Header preloader={preloader} possessions={false} logout={logout} data={data} changeLocale={handleChangeLocale} locale={locale} totalVolume={dataInfo?.totalVolume}/>

            </div>
            <main className="px-8 py-5 shadow-lg bg-white">
                <div className="">
                    <h1 className="sr-only">Kundenportal</h1>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="grid grid-cols-1 items-start gap-5 lg:grid-cols-6">
                           <div className="grid col-span-1 lg:col-span-2 px-4 py-3 bg-accent-2 rounded-lg">
                               {!data ? (
                                  <PreloaderPossessions/>
                               ) : (
                                   <List
                                       counter={counterPossessions}
                                       setValue={setValue}
                                       setShowNotification={setShowNotification}
                                       setSendButton={setSendButton}
                                       watch={watch}
                                       register={register}
                                       dataInfo={dataInfo}
                                       setDataInfo={setDataInfo}
                                       listType={mainPossessions}
                                       open={false}
                                   />
                               )}


                            </div>
                            <div className="grid col-span-1 lg:col-span-2 px-4 py-3 bg-accent-2 rounded-lg">
                                {!data ? (
                                    <PreloaderPossessions/>
                                ) : (<List
                                    counter={counterSPossessions}
                                    setValue={setValue}
                                    setShowNotification={setShowNotification}
                                    setSendButton={setSendButton}
                                    watch={watch}
                                    register={register}
                                    dataInfo={dataInfo}
                                    setDataInfo={setDataInfo}
                                    listType={secondaryPossessions}
                                    open={true}

                                />)}
                            </div>
                            <div className="grid col-span-1 lg:col-span-2 px-4 py-5 bg-accent-2 rounded-lg">
                                {sendButton&&
                                    <button className="fixed flex items-center justify-center text-center bottom-0 right-0 opacity-90 hover:opacity-100 bg-accent-1 z-20 px-5 py-3 let-0 w-full text-white font-semibold text-lg" type="submit">
                                        {preloadButton ?
                                            <span className="text-white fill-white flex items-center">
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                  <path className="opacity-75" fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            In Bearbeitung...
                                        </span>
                                        :<span>Speichern & Absenden</span>
                                        }
                                    </button>
                                }


                                {!data ? (
                                    <PreloaderPossessions/>
                                ) : (<List
                                    counter={counterSidePossessions}
                                    setValue={setValue}
                                    setShowNotification={setShowNotification}
                                    setSendButton={setSendButton}
                                    watch={watch}
                                    register={register}
                                    dataInfo={dataInfo}
                                    setDataInfo={setDataInfo}
                                    listType={sidebarPossessions}
                                    open={true}

                                />)}

                              {/*  <Disclosure className=" px-4 py-7 relative" as="div">
                                    <h3 className="-mx-4 -my-5 flow-root sticky top-0 lg:top-[188px] px-4 shadow-md rounded-lg  bg-white">
                                        <Disclosure.Button className=" flex w-full items-center justify-between py-6 text-gray-400 hover:text-gray-500">
                                            <span className="font-medium text-accent-1 flex">
                                                <FormattedMessage id="setup" />
                                            </span>
                                    </Disclosure.Button>
                                    </h3>
                                    <Disclosure.Panel className="pt-6">
                                        <div className="">
hola
                                        </div>
                                    </Disclosure.Panel>
                                </Disclosure>*/}
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
}